import * as React from "react";
import {
  createDefaultOrganisation,
  OrganisationLineItem,
} from "social-pro-common/interfaces/organisation";
import { decodeOrganisation } from "social-pro-common/decoders/organisation";
import { listApiData } from "@hooks/utils/api";
import { getImageUrl } from "@hooks/utils/useUpload";
import { Organisation } from "social-pro-common/entities/organisation";
import { IS_TEST_ENV } from "init";

export const useOrganisationByABN = () => {
  const [isOrganisationLoading, setIsLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);

  const searchOrganisationExistsByABN = async (
    query: string,
  ): Promise<OrganisationLineItem | null> => {
    try {
      setIsLoading(true);
      const organisationResult = await listApiData(
        "searchOrganisationExistsByABN",
        "organisation",
        query,
      );

      if (organisationResult.data && organisationResult.data.length) {
        const decodedOrganisations = await decodeOrganisation(
          organisationResult.data[0] as Organisation,
          getImageUrl,
        );

        return decodedOrganisations;
      }
      return null;
    } catch (e: any) {
      setError("Could not search organisations");
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const searchOrganisationsByAbn = async (
    query: string,
  ): Promise<OrganisationLineItem | null> => {
    try {
      setIsLoading(true);
      const organisationResult = await listApiData(
        "searchOrganisationsByABN",
        "organisation",
        query,
      );

      if (organisationResult.data && organisationResult.data.length) {
        const decodedOrganisations = await decodeOrganisation(
          organisationResult.data[0] as Organisation,
          getImageUrl,
        );

        return decodedOrganisations;
      }

      if (IS_TEST_ENV) {
        //The api that searches for an ABN company or existing company will nearly always return false
        const contractorOrganisation = createDefaultOrganisation(
          "mock_organisation@email.com",
        );
        contractorOrganisation.organisationName = "SO";
        contractorOrganisation.tradingName = "SO";
        contractorOrganisation.abn = query;
        contractorOrganisation.isSetup = false;
        contractorOrganisation.transient = true;
        return contractorOrganisation;
      }

      return null;
    } catch (e: any) {
      setError("Could not search organisations");
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    error,
    isOrganisationLoading,
    searchOrganisationExistsByABN,
    searchOrganisationsByAbn,
  };
};
