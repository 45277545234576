import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {
  CardHeader,
  CircularProgress,
  Grid,
  InputAdornment,
  Skeleton,
} from "@mui/material";
import { debounce } from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import { PackageLocalContentLineItem } from "social-pro-common/interfaces/packageLocalContent";

interface LidpFinderProps {
  loading: boolean;
  projectId: string;
  id: string;
  name: string;
  label: string;
  disabled: boolean;
  error?: boolean;
  helperText?: string;
  existingLocalContent: PackageLocalContentLineItem | undefined;
  handleSearch: (
    projectId: string,
    query: string,
  ) => Promise<PackageLocalContentLineItem[]>;
  onChange: (value?: PackageLocalContentLineItem) => void;
  onBlur: (e: React.ChangeEvent<any>) => void;
}

export const LidpFinder = ({
  disabled,
  error,
  existingLocalContent,
  handleSearch,
  helperText,
  id,
  label,
  loading,
  name,
  onBlur,
  onChange,
  projectId,
}: LidpFinderProps) => {
  const [isSearching, setIsSeaching] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");

  const [value, setValue] = React.useState<
    PackageLocalContentLineItem | undefined
  >(existingLocalContent);
  const [options, setOptions] = React.useState<PackageLocalContentLineItem[]>(
    [],
  );

  const runSearch = async (searchQuery: string) => {
    setIsSeaching(true);
    setOptions([]);
    const searchResults = await handleSearch(projectId, searchQuery);
    setOptions(searchResults);
    setIsSeaching(false);
  };
  const fetch = React.useRef(debounce(runSearch, 500));

  React.useEffect(() => {
    if (inputValue.length > 3) {
      fetch.current(inputValue);
    }
  }, [value, inputValue, fetch]);

  if (loading) {
    return (
      <Skeleton animation="wave" variant="rounded" width={"100%"}>
        <TextField label="DEFAULT TEXT FIELD CONTENT - LOADING" fullWidth />
      </Skeleton>
    );
  }

  return (
    <Autocomplete
      fullWidth
      loading={isSearching}
      disabled={disabled}
      getOptionLabel={(option) => option.itemDescription}
      filterOptions={(x) => x}
      filterSelectedOptions
      options={options}
      value={value}
      noOptionsText="No matching item found."
      onChange={(event: any, newValue: PackageLocalContentLineItem | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue || undefined);
        onChange(newValue || undefined);
      }}
      onBlur={onBlur}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      sx={{}}
      renderInput={(params) => (
        <TextField
          data-test-id="localContent-finder"
          {...params}
          id={id}
          name={name}
          label={label}
          fullWidth
          error={error}
          helperText={helperText}
          disabled={loading}
          value={inputValue}
          InputProps={{
            ...params.InputProps,
            endAdornment: isSearching ? (
              <InputAdornment
                position="start"
                sx={{
                  color: disabled ? "rgba(0, 0, 0, 0.26)" : undefined,
                }}
              >
                <CircularProgress size={20} data-test-id={"lidp-search-icon"} />
              </InputAdornment>
            ) : undefined,
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{
                  color: disabled ? "rgba(0, 0, 0, 0.26)" : undefined,
                }}
              >
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex" }}>
                <CardHeader
                  sx={{ padding: "0px" }}
                  titleTypographyProps={{
                    fontSize: "14px !important",
                    fontWeight: 400,
                    padding: "0px !important",
                  }}
                  title={option.itemDescription}
                />
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};
