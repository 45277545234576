import * as React from "react";
import { Auth } from "aws-amplify";
import { UserLineItem } from "social-pro-common/interfaces/user";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";
import { useProfile } from "@hooks/crud/profile/useProfile";
import { CognitoUser } from "@aws-amplify/auth";
import { PageLoader } from "@stories/atoms/Loaders/PageLoader/PageLoader";
import { API_END_POINT_AUTH, IS_PROD_ENV } from "init";
import { formattedUserName } from "social-pro-common/utils/string";
import { catchSentryError } from "@utils/sentry";

type AuthResponse = {
  authenticated: boolean;
  error?: string;
};

export enum AuthenticateChallenge {
  NONE = "NONE",
  NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED",
  AUTH_ERROR = "AUTH_ERROR",
}

type AuthUser = CognitoUser & {
  challengeName?: AuthenticateChallenge;
  preferredMFA?: AuthenticateChallenge;
};

type AC = {
  loggedIn: boolean;
  isFirstLoad: boolean;
  user?: UserLineItem;
  isAuthLoading: boolean;
  userProfile?: ProfileLineItem;
  isContractor: boolean;
  isSocialProAdmin: boolean;
  setUserProfile: (profile: ProfileLineItem) => void;
  isAuthenticated: () => Promise<boolean>;
  federatedSignIn: () => Promise<void>;
  authenticate: (email: string) => Promise<AuthResponse>;
  signIn: (
    username: string,
    password: string,
  ) => Promise<AuthenticateChallenge>;
  signUp: (
    username: string,
    password: string,
    isContractor: boolean,
  ) => Promise<void>;
  forgotPassword: (username: string) => Promise<void>;
  forgotPasswordSubmit: (
    username: string,
    code: string,
    newPassword: string,
  ) => Promise<string>;
  resetPassword: (
    username: string,
    oldPassword: string,
    newPassword: string,
  ) => Promise<void>;
  changePassword: (oldPassword: string, newPassword: string) => Promise<void>;
  answerCustomChallenge: (email: string, answer: string) => Promise<boolean>;
  signOut: () => Promise<void>;
};

export const AuthContext = React.createContext<AC>({
  answerCustomChallenge: () => Promise.resolve(false),
  authenticate: () => Promise.resolve({ authenticated: false }),
  changePassword: () => Promise.resolve(),
  federatedSignIn: () => Promise.resolve(),
  forgotPassword: () => Promise.resolve(),
  forgotPasswordSubmit: () => Promise.resolve(""),
  isAuthLoading: false,
  isAuthenticated: () => Promise.resolve(false),
  isContractor: false,
  isFirstLoad: false,
  isSocialProAdmin: false,
  loggedIn: false,
  resetPassword: () => Promise.resolve(),
  setUserProfile: () => {
    return;
  },
  signIn: () => Promise.resolve(AuthenticateChallenge.NONE),
  signOut: () => Promise.resolve(),
  signUp: () => Promise.resolve(),
});

type AuthProviderProps = {
  children: React.ReactNode;
};

export const requestMagicLink = async (email: string) => {
  const res = await fetch(API_END_POINT_AUTH, {
    body: JSON.stringify({ email }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
  });
  return res.json();
};

const AuthProvider = (props: AuthProviderProps) => {
  const [user, setUser] = React.useState<UserLineItem>();
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [isAuthLoading, setIsAuthLoading] = React.useState(true);
  const [isFirstLoad, setIsFirstLoad] = React.useState(true);
  const { getProfile } = useProfile();
  const [userProfile, setUserProfile] = React.useState<ProfileLineItem>();

  const getUserId = (cognitoUser: AuthUser) => {
    const payload = cognitoUser.getSignInUserSession()?.getIdToken().payload;
    if (!payload) {
      throw new Error("User not configured correctly.");
    }
    const userId = payload["cognito:username"] as string;
    if (!userId) {
      throw new Error("User userId id not available");
    }
    return formattedUserName(userId);
  };

  const isAuthenticated = async () => {
    try {
      const res = await Auth.currentAuthenticatedUser();
      const email = res.signInUserSession.idToken.payload.email;
      const userId = getUserId(res);
      const groups =
        res.signInUserSession.accessToken.payload["cognito:groups"];
      const isContractor = groups?.includes("Contractor");
      const isSocialProAdmin = groups?.includes("SocialProAdmin");
      setUser({ email: email, id: userId, isContractor, isSocialProAdmin });
      const profile = await getProfile(userId);
      if (profile) {
        setUserProfile(profile);
        return true;
      }
      return true;
    } catch (e) {
      catchSentryError(e);
      return false;
    } finally {
      setIsAuthLoading(false);
    }
  };

  React.useEffect(() => {
    isAuthenticated()
      .then((res) => {
        setLoggedIn(res);
        setIsAuthLoading(false);
      })
      .finally(() => {
        setIsFirstLoad(false);
      });
  }, []);

  const authenticate = async (email: string) => {
    try {
      setIsAuthLoading(true);
      await requestMagicLink(email);
      setIsAuthLoading(false);
      return { authenticated: true };
    } catch (e) {
      catchSentryError(e);
      setIsAuthLoading(false);
      return { authenticated: false };
    }
  };

  const federatedSignIn = async (): Promise<void> => {
    try {
      await Auth.federatedSignIn({ provider: "BMD-SAML-IDP" } as any);
      const res = await Auth.currentAuthenticatedUser();
      const email = res.signInUserSession.idToken.payload.email;
      const userId = getUserId(res);
      const groups =
        res.signInUserSession.accessToken.payload["cognito:groups"];
      const isContractor = groups?.includes("Contractor");
      const isSocialProAdmin = groups?.includes("SocialProAdmin");
      setUser({ email: email, id: userId, isContractor, isSocialProAdmin });
      const profile = await getProfile(userId);
      if (profile) {
        setUserProfile(profile);
      }
      setLoggedIn(true);
    } catch (e) {
      catchSentryError(e);
    }
  };

  const signIn = async (
    username: string,
    password: string,
  ): Promise<AuthenticateChallenge> => {
    try {
      const res = await Auth.signIn(username, password);
      const challengeName = res.challengeName || AuthenticateChallenge.NONE;
      if (challengeName === AuthenticateChallenge.NONE) {
        const user = await Auth.currentAuthenticatedUser();

        const email = user.signInUserSession.idToken.payload.email;
        const userId = getUserId(user);
        const groups =
          user.signInUserSession.accessToken.payload["cognito:groups"];
        const isContractor = groups?.includes("Contractor");
        const isSocialProAdmin = groups?.includes("SocialProAdmin");
        setUser({ email: email, id: userId, isContractor, isSocialProAdmin });
        const profile = await getProfile(userId);
        if (profile) {
          setUserProfile(profile);
        }
        setLoggedIn(true);
      }
      return challengeName;
    } catch (e) {
      catchSentryError(e);
      return AuthenticateChallenge.AUTH_ERROR;
    }
  };

  const signUp = async (
    email: string,
    password: string,
    isContractor: boolean,
  ): Promise<void> => {
    if (IS_PROD_ENV) {
      throw new Error("Not implemented");
    }
    try {
      const res = await Auth.signUp({
        attributes: {
          "custom:authChallenge": isContractor ? "Contractor" : undefined,
          email,
        },
        password,
        username: email,
      });

      // No email confirmation required, the user is automatically confirmed
      if (res.userConfirmed) {
        console.log("User confirmed during sign-up");
      } else {
        console.log(
          "User needs to confirm registration (bypass email confirmation via Lambda)",
        );
      }
    } catch (error) {
      console.error("Error signing up:", error);
    }
  };

  const changePassword = async (
    oldPassword: string,
    newPassword: string,
  ): Promise<void> => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, newPassword);
    } catch (e) {
      catchSentryError(e);
    }
  };

  const resetPassword = async (
    username: string,
    oldPassword: string,
    newPassword: string,
  ): Promise<void> => {
    try {
      const user = await Auth.signIn(username, oldPassword);
      await Auth.completeNewPassword(user, newPassword);
      const res = await Auth.currentAuthenticatedUser();
      const email = res.signInUserSession.idToken.payload.email;
      const userId = getUserId(res);
      const groups =
        res.signInUserSession.accessToken.payload["cognito:groups"];
      const isContractor = groups?.includes("Contractor");
      const isSocialProAdmin = groups?.includes("SocialProAdmin");
      setUser({ email: email, id: userId, isContractor, isSocialProAdmin });
      const profile = await getProfile(userId);
      if (profile) {
        setUserProfile(profile);
      }
      setLoggedIn(true);
    } catch (e) {
      catchSentryError(e);
    }
  };

  const forgotPassword = async (username: string): Promise<void> => {
    try {
      await Auth.forgotPassword(username);
    } catch (e) {
      catchSentryError(e);
    }
  };

  const forgotPasswordSubmit = async (
    username: string,
    code: string,
    newPassword: string,
  ): Promise<string> => {
    try {
      const res = await Auth.forgotPasswordSubmit(username, code, newPassword);
      return res;
    } catch (e) {
      catchSentryError(e);
      return "";
    }
  };

  const answerCustomChallenge = async (
    email: string,
    answer: string,
  ): Promise<boolean> => {
    try {
      setIsAuthLoading(true);
      const cognitoUser = await Auth.signIn(email);
      const res = await Auth.sendCustomChallengeAnswer(cognitoUser, answer);
      res.getSession(() => {
        res.getUserAttributes((err: any) => {
          if (err) {
            alert(err.message || JSON.stringify(err));
          }
        });
      });
      const groups =
        res.signInUserSession.accessToken.payload["cognito:groups"];
      const isContractor = groups?.includes("Contractor");
      const userId = getUserId(res);
      setUser({
        email: res.username,
        id: userId,
        isContractor,
      } as UserLineItem);
      const profile = await getProfile(userId);
      if (profile) {
        setUserProfile(profile);
      }
      setLoggedIn(true);
      return true;
    } catch (e) {
      catchSentryError(e);
      return false;
    } finally {
      setIsAuthLoading(false);
    }
  };

  const signOut = React.useCallback(async () => {
    try {
      setIsAuthLoading(true);
      await Auth.signOut();
      setLoggedIn(false);
    } catch (e) {
      catchSentryError(e);
    } finally {
      setIsAuthLoading(false);
    }
  }, []);

  if (isFirstLoad) {
    return <PageLoader />;
  }

  return (
    <AuthContext.Provider
      value={{
        answerCustomChallenge,
        authenticate,
        changePassword,
        federatedSignIn,
        forgotPassword,
        forgotPasswordSubmit,
        isAuthLoading,
        isAuthenticated,
        isContractor: user?.isContractor || false,
        isFirstLoad,
        isSocialProAdmin: user?.isSocialProAdmin || false,
        loggedIn,
        resetPassword,
        setUserProfile,
        signIn,
        signOut,
        signUp,
        user,
        userProfile,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => React.useContext(AuthContext);

export { AuthProvider, useAuthContext };
