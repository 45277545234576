import { NavItemType } from "types";
import * as RouteHelper from "@utils/routes";
import TrackChanges from "@mui/icons-material/TrackChanges";
import { useTheme } from "@mui/material";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import {
  CalendarClock,
  CircleDollarSign,
  ScrollText,
  Sprout,
  MapPinned,
} from "lucide-react";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { getMaximalDate } from "social-pro-common/utils/date";

export const getAnalysisNav = (
  project?: ProjectLineItem,
  contractorPackage?: ContractorPackageLineItem,
  isOwner?: boolean,
): NavItemType => {
  const theme = useTheme();

  const currentDate = getMaximalDate(
    isOwner ? project?.projectStartDate : contractorPackage?.packageStartDate,
    isOwner ? project?.projectEndDate : contractorPackage?.packageEndDate,
  );

  const children = [];
  if (contractorPackage?.commitmentsHours.length) {
    children.push({
      icon: <CalendarClock />,
      id: "analytics-labour-hours",
      title: "Labour Hours",
      type: "item",
      url:
        project && contractorPackage
          ? RouteHelper.analyticsLabourHours(
              project?.id,
              contractorPackage?.id,
              currentDate,
            )
          : "",
    });
  }
  if (contractorPackage?.commitmentsSpend.length) {
    children.push({
      icon: <CircleDollarSign />,
      id: "analytics-spend",
      title: "Spend",
      type: "item",
      url:
        project && contractorPackage
          ? RouteHelper.analyticsSpend(
              project.id,
              contractorPackage?.id,
              currentDate,
            )
          : "",
    });
  }

  const isPrimaryPackage = contractorPackage?.id === project?.id;
  if (project?.localContents.length) {
    if (isPrimaryPackage) {
      children.push({
        icon: <MapPinned />,
        id: "analytics-localContent",
        title: "Local Content",
        type: "item",
        url:
          project && contractorPackage
            ? RouteHelper.analyticsLocalContent(
                project.id,
                contractorPackage.id,
              )
            : "",
      });
    } else if (!isPrimaryPackage) {
      children.push({
        icon: <MapPinned />,
        id: "analytics-localContent",
        title: "Local Content",
        type: "item",
        url:
          project && contractorPackage
            ? RouteHelper.analyticsPackageLocalContentDetails(
                project.id,
                contractorPackage.id,
                contractorPackage.id,
              )
            : "",
      });
    }
  }
  if (project?.policies.length) {
    children.push({
      icon: <ScrollText />,
      id: "analytics-policies",
      title: "Policies",
      type: "item",
      url:
        project && contractorPackage
          ? RouteHelper.analyticsPolicies(project.id, contractorPackage.id)
          : "",
    });
  }

  if (project?.greenMetrics?.length) {
    if (isPrimaryPackage) {
      children.push({
        icon: <Sprout />,
        id: "analytics-sustainability",
        title: "Sustainability",
        type: "item",
        url:
          project && contractorPackage
            ? RouteHelper.analyticsEnviroSustainability(
                project.id,
                contractorPackage.id,
                currentDate,
              )
            : "",
      });
    } else if (!isPrimaryPackage) {
      children.push({
        icon: <Sprout />,
        id: "package-analytics-sustainability",
        title: "Sustainability",
        type: "item",
        url:
          project && contractorPackage
            ? RouteHelper.analyticsEnviroSustainabilityDetails(
                project.id,
                contractorPackage.id,
                contractorPackage.id,
                currentDate,
              )
            : "",
      });
    }
  }
  return {
    children,
    icon: <TrackChanges sx={{ color: theme.palette.primary.main }} />,
    id: "analytics",
    title: "Targets", //TODO: change class to analytics
    type: "group",
  };
};
