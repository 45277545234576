import { useProjectContext } from "@hooks/context/useProjectContext";
import * as RouteHelper from "@utils/routes";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { packageTypeToString } from "social-pro-common/interfaces/contractorPackage";
import {
  Box,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useProfileContext } from "@hooks/context/useProfileContext";
import { PackageDetailsHours } from "@stories/organisms/PackageDetails/PackageDetailsHours";
import { PackageDetailsSpend } from "@stories/organisms/PackageDetails/PackageDetailsSpend";
import { PackageDetailsPolicy } from "@stories/organisms/PackageDetails/PackageDetailsPolicy";
import { PackageDetailsEnviroSustainability } from "@stories/organisms/PackageDetails/PackageDetailsEnviroSustainability";
import { PackageReports } from "@stories/organisms/PackageDetails/PackageDetailsReports";
import { PackageDetailsHoursTarget } from "@stories/organisms/PackageDetails/PackageDetailsHoursTarget";
import { PackageDetailsSpendTarget } from "@stories/organisms/PackageDetails/PackageDetailsSpendTarget";
import { MonthSelect } from "@stories/atoms/MonthSelect/MonthSelect";
import {
  PackageDetailsTab,
  PackageDetailsTabOption,
} from "@stories/molecules/PackageDetailsTab/PackageDetailsTab";
import { SummaryDetails } from "@stories/organisms/PackageDetails/PackageDetailsSummary";
import { dateToString, stringToDate } from "social-pro-common/utils/date";
import { PackageAnalyticsLocalContent } from "@stories/molecules/PackageAnalyticsLocalContent/PackageAnalyticsLocalContent";
import { useContractorPackageDetails } from "@hooks/crud/contractorPackages/useContractorPackageDetails";
import { PackageDetailsForecast } from "@stories/organisms/PackageDetails/PackageDetailsForecast";
import { PackageDetailsComments } from "@stories/organisms/PackageDetails/PackageDetailsComments";
import { getSortedLocalContent } from "social-pro-common/interfaces/packageLocalContent";

export const PackageDetails = () => {
  const { packageId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const { isAuthProfileLoading } = useProfileContext();

  const {
    contractorPackage,
    isPrimaryPackage,
    isProjectLoading,
    selectedProject,
  } = useProjectContext();

  const {
    contractorOrganisation,
    contractorPackageDetails,
    isContractorPackageLoading,
  } = useContractorPackageDetails(packageId);

  const [labourHourTargetId, setLabourHourTargetId] = useState<string>();

  const [reportId, setReportDate] = useState<string>(dateToString(new Date()));

  const onLabourHourTargetChange = (targetId?: string) => {
    setLabourHourTargetId(targetId);
  };

  const [socialSpendTargetId, setSocialSpendTargetId] = useState<string>();

  const onSocialSpendTargetChange = (targetId?: string) => {
    setSocialSpendTargetId(targetId);
  };

  const [activeStep, setActiveStep] = useState<PackageDetailsTabOption>(
    PackageDetailsTabOption.Hours,
  );

  const setActiveTab = (activeTab?: PackageDetailsTabOption) => {
    if (activeTab) {
      setLabourHourTargetId(undefined);
      setSocialSpendTargetId(undefined);
      setActiveStep(activeTab);
    }
  };

  const handlOnBack = () => {
    if (selectedProject && contractorPackage) {
      navigate(RouteHelper.packages(selectedProject.id, contractorPackage.id));
    }
  };

  useEffect(() => {
    const tab = searchParams.get("tab");
    if (tab) {
      setActiveTab(PackageDetailsTabOption.Reports);
    }
    setSearchParams();
  }, []);

  const loading =
    isAuthProfileLoading || isProjectLoading || isContractorPackageLoading;

  const selectedPackageStep = (() => {
    switch (activeStep) {
      default:
      case PackageDetailsTabOption.Details:
        return (
          <SummaryDetails
            isLoading={loading}
            project={selectedProject}
            contractorOrganisation={contractorOrganisation}
            contractorPackage={contractorPackageDetails}
          />
        );
      case PackageDetailsTabOption.Hours:
        return !labourHourTargetId ? (
          <PackageDetailsHours
            isLoading={loading}
            contractorPackage={contractorPackageDetails}
            reportId={reportId}
            onLabourHourTargetChange={onLabourHourTargetChange}
          />
        ) : (
          <PackageDetailsHoursTarget
            isLoading={loading}
            contractorPackage={contractorPackageDetails}
            targetId={labourHourTargetId}
            reportId={reportId}
            handlOnBack={() => {
              setLabourHourTargetId(undefined);
            }}
          />
        );

      case PackageDetailsTabOption.Spend:
        return !socialSpendTargetId ? (
          <PackageDetailsSpend
            isLoading={loading}
            contractorPackage={contractorPackageDetails}
            reportId={reportId}
            onSocialSpendTargetChange={onSocialSpendTargetChange}
          />
        ) : (
          <PackageDetailsSpendTarget
            isLoading={loading}
            contractorPackage={contractorPackageDetails}
            targetId={socialSpendTargetId}
            reportId={reportId}
            handleOnBack={() => {
              setSocialSpendTargetId(undefined);
            }}
          />
        );
      case PackageDetailsTabOption.LocalContent:
        return (
          <PackageAnalyticsLocalContent
            loading={loading}
            reportId={reportId}
            isPrimaryPackage={isPrimaryPackage}
            contractorPackage={contractorPackageDetails}
            localContents={getSortedLocalContent(
              contractorPackageDetails?.localContent,
              reportId,
            )}
          />
        );
      case PackageDetailsTabOption.Policy:
        return (
          <PackageDetailsPolicy
            isLoading={loading}
            contractorPackage={contractorPackageDetails}
          />
        );
      case PackageDetailsTabOption.Enviro:
        return (
          <PackageDetailsEnviroSustainability
            loading={loading}
            project={selectedProject}
            contractorPackage={contractorPackageDetails}
            reportId={reportId}
          />
        );
      case PackageDetailsTabOption.Forecast:
        return (
          <PackageDetailsForecast
            isLoading={loading}
            contractorPackage={contractorPackageDetails}
          />
        );
      case PackageDetailsTabOption.Reports:
        return (
          <PackageReports
            isLoading={loading}
            contractorPackage={contractorPackage}
            subPackage={contractorPackageDetails}
          />
        );
      case PackageDetailsTabOption.Comments:
        return (
          <PackageDetailsComments
            isLoading={loading}
            project={selectedProject}
            organisation={contractorOrganisation}
            contractorPackage={contractorPackageDetails}
          />
        );
    }
  })();

  return (
    <>
      <Grid
        className="borderBottom"
        container
        spacing={2}
        sx={{
          alignItems: "center",
          display: "flex",
          margin: "0",
          padding: "20px 40px 20px 40px",
          width: "100%",
        }}
      >
        <Grid item xs={12} md={6} sx={{ padding: "0 !important" }}>
          {loading ? (
            <Grid sx={{ alignItems: "center", display: "flex", gap: "10px" }}>
              <Skeleton animation="wave" variant="circular">
                <IconButton color="primary" size="large">
                  <ArrowBackIosNewOutlinedIcon />
                </IconButton>
              </Skeleton>
              <Skeleton animation="wave">
                <Typography variant="h2">
                  {"Project Dashboard September 2000"}
                </Typography>
                <Typography variant="subtitle1">
                  {"Project Dashboard September 2000"}
                </Typography>
              </Skeleton>
            </Grid>
          ) : (
            <>
              <Grid sx={{ alignItems: "center", display: "flex", gap: "10px" }}>
                <Tooltip
                  TransitionComponent={Zoom}
                  placement="top"
                  title="Back"
                >
                  <IconButton
                    data-test-id="back-button-top"
                    size="large"
                    onClick={handlOnBack}
                    sx={{
                      color: "black",
                      height: "auto",
                      padding: "5px",
                      width: "auto",
                    }}
                  >
                    <ArrowBackIosNewOutlinedIcon sx={{ fontSize: "30px" }} />
                  </IconButton>
                </Tooltip>
                <Stack>
                  <Typography variant="h2">
                    {contractorPackageDetails?.contractorName}
                  </Typography>
                  <Typography variant="subtitle1">
                    {`${
                      contractorPackageDetails
                        ? packageTypeToString(
                            contractorPackageDetails?.packageType,
                          )
                        : ""
                    } `}
                  </Typography>
                </Stack>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          alignItems: "center",
          display: "flex",
          margin: "0",
          padding: "30px 40px 20px 40px !important",
          width: "100%",
        }}
      >
        <Grid item xs={2} md={12} sx={{ padding: "0 !important" }}>
          <Stack direction="row" spacing={2}>
            <Box className="newStyleField monthSelect">
              <MonthSelect
                data-test-id="month-select"
                loading={loading}
                project={selectedProject}
                contractorPackage={contractorPackageDetails}
                isPrimaryPackage={false}
                setDate={(date: Date) => {
                  setReportDate(dateToString(date));
                }}
                date={stringToDate(reportId)}
              />
            </Box>
            <PackageDetailsTab
              isLoading={loading}
              activeTab={activeStep}
              project={selectedProject}
              contractorPackage={contractorPackageDetails}
              showComments={true}
              setActiveTab={setActiveTab}
            />
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{ padding: "30px 0px 50px !important" }}
          className="contract-table glb-table-sty"
        >
          {selectedPackageStep}
        </Grid>
      </Grid>
    </>
  );
};
