import * as React from "react";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";
import { ToastOptions, toast } from "react-toastify";
import {
  ProjectLineItem,
  createDefaultProject,
} from "social-pro-common/interfaces/project";
import CloseIcon from "@mui/icons-material/Close";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";
import { ProjectSteps } from "@stories/molecules/ProjectSteps/ProjectSteps";
import { GreenMetricRequirementForm } from "@stories/molecules/GreenMetricRequirementForm/GreenMetricRequirementForm";
import { PolicyRequirementForm } from "@stories/molecules/PolicyRequirementForm/PolicyRequirementForm";
import { SocialRequirementFormSpend } from "@stories/molecules/SocialRequirementFormSpend/SocialRequirementFormSpend";
import { SocialRequirementFormHours } from "@stories/molecules/SocialRequirementFormHours/SocialRequirementFormHours";
import { ProjectForm } from "@stories/molecules/ProjectForm/ProjectForm";
import { GreenMetricLineItem } from "social-pro-common/interfaces/greenMetric";
import { useProject } from "@hooks/crud/project/useProject";
import { LidpRequirementForm } from "@stories/molecules/LidpRequirementForm/LidpRequirementForm";
import { calculateProjectProgressLineItem } from "social-pro-common/utils/calc";
import { getSortedLocalContent } from "social-pro-common/interfaces/packageLocalContent";
import { ConfirmationDialog } from "@stories/molecules/ConfirmationDialog/ConfirmationDialog";

interface ProjectEditModalProps {
  open: boolean;
  isLoading: boolean;
  projectId?: string;
  userProfile: ProfileLineItem;
  userOrganisation: OrganisationLineItem;
  createProject: (project: ProjectLineItem) => Promise<void>;
  updateProject: (project: ProjectLineItem) => Promise<void>;
  handleClose: () => void;
}

export default function ProjectEditModal({
  createProject,
  handleClose,
  isLoading,
  open,
  projectId,
  updateProject,
  userOrganisation,
  userProfile,
}: ProjectEditModalProps) {
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [isDirty, setIsDirty] = React.useState(false);

  const [activeStep, setActiveStep] = React.useState(0);

  const { isProjectLoading, selectedProject } = useProject(
    undefined,
    projectId,
  );

  const [project, setProject] = React.useState(createDefaultProject());

  React.useEffect(() => {
    if (selectedProject) {
      setProject(selectedProject);
      setFirstLoad(false);
    } else if (!projectId) {
      if (userProfile && userOrganisation) {
        setProject(createDefaultProject(userProfile, userOrganisation));
        setFirstLoad(false);
      }
    }
  }, [selectedProject, userProfile, userOrganisation]);

  const onSubmit = async (greenMetrics: GreenMetricLineItem[]) => {
    try {
      project.greenMetrics = greenMetrics;
      project.estimatedProgress = calculateProjectProgressLineItem(
        project.commitmentsHours,
        project.commitmentsSpend,
      );

      if (projectId) {
        await updateProject(project);
        toast("Project updated!", {
          type: "success",
        } as ToastOptions);
        handleClose();
      } else {
        await createProject(project);
        toast("Project created!", {
          type: "success",
        } as ToastOptions);
        handleClose();
      }
    } catch (e) {
      toast("Could not update project, contact support!", {
        type: "success",
      } as ToastOptions);
    }
  };

  const setDirtyOnChange = (isDirty: boolean) => {
    setIsDirty(isDirty);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const loading =
    isLoading || (projectId ? isProjectLoading : false) || firstLoad;

  const selectedCreateProjectStep = (() => {
    switch (activeStep) {
      default:
      case 0:
        return (
          <ProjectForm
            loading={loading}
            handleNext={handleNext}
            project={project}
            setProject={setProject}
            setDirtyOnChange={setDirtyOnChange}
          />
        );

      case 1:
        return (
          <SocialRequirementFormHours
            loading={loading}
            handleBack={handleBack}
            handleNext={handleNext}
            title={"Hours"}
            project={project}
            commitmentLineItems={project?.commitmentsHours}
            setProject={setProject}
            setDirtyOnChange={setDirtyOnChange}
          />
        );
      case 2:
        return (
          <SocialRequirementFormSpend
            loading={loading}
            handleBack={handleBack}
            handleNext={handleNext}
            title={"Spend"}
            project={project}
            commitmentLineItems={project?.commitmentsSpend}
            setProject={setProject}
            setDirtyOnChange={setDirtyOnChange}
          />
        );
      case 3: {
        return (
          <LidpRequirementForm
            loading={loading}
            handleNext={handleNext}
            handleBack={handleBack}
            project={project}
            isPrimaryPackage={true}
            projectLocalContent={getSortedLocalContent(project?.localContents)}
            setProject={setProject}
            setDirtyOnChange={setDirtyOnChange}
          />
        );
      }
      case 4:
        return (
          <PolicyRequirementForm
            loading={loading}
            handleNext={handleNext}
            handleBack={handleBack}
            project={project}
            projectPolicies={project?.policies}
            setProject={setProject}
            setDirtyOnChange={setDirtyOnChange}
          />
        );
      case 5:
        return (
          <GreenMetricRequirementForm
            loading={loading}
            handleBack={handleBack}
            project={project}
            greenMetrics={project?.greenMetrics}
            onSubmit={onSubmit}
            setProject={setProject}
            setDirtyOnChange={setDirtyOnChange}
          />
        );
    }
  })();

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={"lg"}
        open={open}
        className="bgBlueOverlay addProjectModal  custom-scrollbar"
      >
        <IconButton
          aria-label="close"
          onClick={() => {
            if (!isDirty) {
              handleClose();
            } else {
              setOpenConfirmDialog(true);
            }
          }}
          sx={{
            "&:hover": {
              backgroundColor: "#143E7C",
            },
            background: "black",
            color: "white",
            padding: "5px",
            position: "absolute",
            right: 24,
            top: 30,
            zIndex: 10,
          }}
        >
          <CloseIcon />
        </IconButton>
        <ProjectSteps activeStep={activeStep} loading={loading} />
        <DialogContent
          className="custom-scrollbar"
          sx={{ borderRadius: "12px", padding: "0" }}
        >
          <div style={{ margin: "0" }} className="modalContent">
            {selectedCreateProjectStep}
          </div>
        </DialogContent>
      </Dialog>
      <ConfirmationDialog
        message={
          "Do you want to exit without saving? Otherwise, complete the project sections to save changes."
        }
        open={openConfirmDialog}
        title={"Project changes detected"}
        intent={"error"}
        cancelButtonText={"Continue"}
        buttonText={"Exit"}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={() => {
          setOpenConfirmDialog(false);
          handleClose();
        }}
      />
    </>
  );
}
