import * as ExcelJs from "exceljs";
import { EmployeeSocialFactor } from "social-pro-common/entities/contractorEmployee";
import {
  createDefaultEmployee,
  EmployeeLineItem,
  stringImportToContractType,
  stringImportToEmploymentLevel,
  stringImportToEmploymentType,
  stringImportToGender,
  stringImportToNewExistingJobType,
  stringImportToOccupationType,
} from "social-pro-common/interfaces/contractorEmployee";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";
import { validateABN } from "social-pro-common/utils/abn";
import { isValidDate } from "social-pro-common/utils/date";

interface XlsxEmployeeResult {
  employees: EmployeeLineItem[];
  errorMessage?: string;
}

export const readXlsxFileToEmployee = async (
  userOrganisation: OrganisationLineItem,
  file: File,
): Promise<XlsxEmployeeResult> => {
  const DATA_START_ROW = 12;
  let errorMessage: string;
  return new Promise((resolve, reject) => {
    const wb = new ExcelJs.Workbook();
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        const data = new Uint8Array(reader.result as ArrayBuffer);
        wb.xlsx.load(data).then((workbook: ExcelJs.Workbook) => {
          const employees = [] as EmployeeLineItem[];
          let count = 0;

          const idMap = new Map();

          workbook.eachSheet((sheet: ExcelJs.Worksheet) => {
            if (count == 0) {
              sheet.eachRow((row: ExcelJs.Row, rowIndex: number) => {
                if (rowIndex >= DATA_START_ROW) {
                  const values = row.values as ExcelJs.CellValue[];
                  let employeeCode = values[2] as string | number | undefined;
                  if (employeeCode && typeof employeeCode !== "string") {
                    employeeCode = employeeCode.toString().trim();
                  }
                  if (
                    employeeCode &&
                    (employeeCode as string).length > 0 &&
                    employeeCode !==
                      "Insert rows to enter more data as required"
                  ) {
                    const newEmployee = createDefaultEmployee(userOrganisation);
                    newEmployee.employeeName = (employeeCode as string).trim();
                    newEmployee.employeeIdentifier = values[3]
                      ? (values[3] as string)
                      : undefined;

                    const parsedGender = stringImportToGender(
                      values[4] as string,
                    );
                    if (!parsedGender) {
                      reject(`Error reading gender on line ${rowIndex}`);
                    } else {
                      newEmployee.gender = parsedGender;
                    }

                    const dob = values[5] as Date;
                    if (dob && isValidDate(dob)) {
                      newEmployee.dob = dob;
                    }

                    const postCode = values[6] as string;
                    if (postCode) {
                      newEmployee.postCode = postCode;
                    }

                    const employmentType = values[7] as string;
                    const parsedEmploymentType =
                      stringImportToEmploymentType(employmentType);
                    if (!parsedEmploymentType) {
                      reject(
                        `Error reading employment type on line ${rowIndex}`,
                      );
                    } else {
                      newEmployee.employmentType = parsedEmploymentType;
                    }

                    const primaryEmployer = values[8] as string;
                    if (!primaryEmployer) {
                      reject(
                        `Error reading primary employer on line ${rowIndex}`,
                      );
                    }
                    newEmployee.primaryEmployer = primaryEmployer;

                    const employerAbn = values[9] as string;
                    if (!employerAbn || !validateABN(employerAbn.toString())) {
                      reject(`Error reading employer abn on line ${rowIndex}`);
                    }
                    newEmployee.employerAbn = employerAbn;

                    const occupation = values[10] as string;
                    if (!occupation) {
                      reject(`Error reading occupation on line ${rowIndex}`);
                    }
                    newEmployee.occupation = occupation;

                    const occupationType = values[11] as string;
                    const parsedOccupationType = stringImportToOccupationType(
                      occupationType || "",
                    );
                    if (!parsedOccupationType) {
                      reject(
                        `Error reading occupation type on line ${rowIndex}`,
                      );
                    } else {
                      newEmployee.occupationType = parsedOccupationType;
                    }

                    const newExistingJob = values[12] as string;
                    if (!newExistingJob) {
                      reject(
                        `Error reading new/existing job type on line ${rowIndex}`,
                      );
                    }
                    newEmployee.newExistingJob =
                      stringImportToNewExistingJobType(newExistingJob || "");

                    const contractType = values[13] as string;
                    if (contractType) {
                      newEmployee.contractType =
                        stringImportToContractType(contractType);
                    }
                    const employmentLevel = stringImportToEmploymentLevel(
                      (values[14] as string) || "",
                    );
                    if (!employmentLevel) {
                      reject(
                        `Error reading Apprentice/Trainee/Cadet on line ${rowIndex}`,
                      );
                    } else {
                      newEmployee.employmentLevel = employmentLevel;
                    }
                    if (values[15] && values[15] === "TRUE") {
                      newEmployee.socialFactors.push(
                        EmployeeSocialFactor.AboriginalOrTorresStraitIslander,
                      );
                    }
                    if (values[16] && values[16] === "TRUE") {
                      newEmployee.socialFactors.push(
                        EmployeeSocialFactor.AsylumSeekerRefugee,
                      );
                    }
                    if (values[17] && values[17] === "TRUE") {
                      newEmployee.socialFactors.push(
                        EmployeeSocialFactor.DisengagedYouth,
                      );
                    }
                    if (values[18] && values[18] === "TRUE") {
                      newEmployee.socialFactors.push(
                        EmployeeSocialFactor.LongTermUnemployed,
                      );
                    }
                    if (values[19] && values[19] === "TRUE") {
                      newEmployee.socialFactors.push(
                        EmployeeSocialFactor.PersonWithDisability,
                      );
                    }
                    if (values[20] && values[20] === "TRUE") {
                      newEmployee.socialFactors.push(
                        EmployeeSocialFactor.Veteran,
                      );
                    }

                    if (values[21] && values[21] === "TRUE") {
                      newEmployee.socialFactors.push(
                        EmployeeSocialFactor.CulturallyDiverseMigrant,
                      );
                    }
                    if (values[22] && values[22] === "TRUE") {
                      newEmployee.socialFactors.push(
                        EmployeeSocialFactor.PersonsAffectedByFamilyViolence,
                      );
                    }
                    if (values[23] && values[23] === "TRUE") {
                      newEmployee.socialFactors.push(
                        EmployeeSocialFactor.JobseekerWithAMentalIllness,
                      );
                    }
                    if (values[24] && values[24] === "TRUE") {
                      newEmployee.socialFactors.push(
                        EmployeeSocialFactor.LivingInRegionsExperienceingEntrenchedDisadvantage,
                      );
                    }
                    if (values[25] && values[25] === "TRUE") {
                      newEmployee.socialFactors.push(
                        EmployeeSocialFactor.MatureAgedJobSeeker,
                      );
                    }
                    if (values[26] && values[26] === "TRUE") {
                      newEmployee.socialFactors.push(
                        EmployeeSocialFactor.WomenOver45,
                      );
                    }
                    if (values[27] && values[27] === "TRUE") {
                      newEmployee.socialFactors.push(
                        EmployeeSocialFactor.SingleParent,
                      );
                    }
                    if (values[28] && values[28] === "TRUE") {
                      newEmployee.socialFactors.push(
                        EmployeeSocialFactor.SocialHousingTenant,
                      );
                    }
                    if (values[29] && values[29] === "TRUE") {
                      newEmployee.socialFactors.push(
                        EmployeeSocialFactor.WorkersInTransitionRetrenchedWorked,
                      );
                    }
                    if (values[30] && values[30] === "TRUE") {
                      newEmployee.socialFactors.push(
                        EmployeeSocialFactor.YoungPeopleInOutOfHomeCare,
                      );
                    }

                    if (!idMap.has(newEmployee.employeeName)) {
                      idMap.set(newEmployee.employeeName, rowIndex);
                      employees.push(newEmployee);
                    } else {
                      reject(
                        `Duplicate employee codes ${newEmployee.employeeName} at ${rowIndex} and ${idMap.get(newEmployee.employeeName)}.`,
                      );
                    }
                  }
                }
              });
            }
            count += 1;
          });
          resolve({ employees, errorMessage });
        });
      } else {
        reject("Error reading file");
      }
    };
    reader.readAsArrayBuffer(file);
  });
};
