import { TableCell, TableRow, Button, Typography, Stack } from "@mui/material";
import { ReportSubmissionLineItem } from "social-pro-common/interfaces/reportSubmission";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import {
  formatReportDate,
  reportIdToReportingDate,
  stringToDate,
} from "social-pro-common/utils/date";
import { ReportStatusChip } from "@stories/atoms/ReportStatusChip/SuccessChip";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { ReportStatus } from "social-pro-common/entities/projectReportSubmission";
import { useState } from "react";
import { RejectReportDialog } from "../RejectReportDialog/RejectReportDialog";
import { CoolDownButton } from "@stories/atoms/CooldownButton/CooldownButton";
import { AcceptReportDialog } from "../AcceptReportDialog/AcceptReportDialog";

interface ReportTableRowProps {
  project: ProjectLineItem;
  reportSubmission: ReportSubmissionLineItem;
  reportId: string;
  selectedStatus?: ReportStatus;
  subPackage: ContractorPackageLineItem;
  isPrimaryPackage: boolean;
  handleDownloadReport: (contractorPackageId: string, reportId: string) => void;
  handlViewReport: (reportMonthId: string) => void;
  handleAcceptReport: (
    reportSubmission: ReportSubmissionLineItem,
  ) => Promise<void>;
  handleRevertReport: (
    reportSubmission: ReportSubmissionLineItem,
    rejectionMessage: string,
  ) => Promise<void>;
  sendReportReminder: (contractorPackageId: string, reportId: string) => void;
}

export const ReportTableRow = ({
  handlViewReport,
  handleAcceptReport,
  handleDownloadReport,
  handleRevertReport,
  isPrimaryPackage,
  project,
  reportId,
  reportSubmission,
  sendReportReminder,
  subPackage,
}: ReportTableRowProps) => {
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openAcceptModal, setOpenAcceptModal] = useState(false);
  const reportStatus = reportSubmission.reportStatus;
  return (
    <TableRow>
      <TableCell align="left">{`${formatReportDate(
        stringToDate(reportId),
      )}`}</TableCell>
      <TableCell align="center">
        {reportIdToReportingDate(
          reportId,
          project?.reportingDueDate,
        ).toLocaleDateString("en-GB")}
      </TableCell>
      <TableCell
        align="center"
        sx={{
          display: "table-cell",
          justifyContent: "center",
        }}
      >
        <Typography align="center">
          <ReportStatusChip reportStatus={reportSubmission.reportStatus} />
        </Typography>
      </TableCell>
      <TableCell
        align="right"
        className="right"
        sx={{ paddingRight: "20px !important" }}
      >
        <Stack
          direction="row"
          spacing={2}
          alignContent={"end"}
          justifyContent={"end"}
        >
          {reportStatus === ReportStatus.Complete ||
          reportStatus === ReportStatus.Accepted ? (
            <Button
              color="primary"
              size="large"
              onClick={() => handlViewReport(reportId)}
              className="blackBtn"
              sx={{
                color: "white",
                fontSize: "14px",
                lineHeight: "16px",
                padding: "10px 15px",
              }}
            >
              View
            </Button>
          ) : null}
          {isPrimaryPackage && reportStatus === ReportStatus.Complete ? (
            <Button
              color="primary"
              size="large"
              className="blackBtn"
              sx={{
                backgroundColor: "red",
                color: "white",
                fontSize: "14px",
                lineHeight: "16px",
                padding: "10px 15px",
              }}
              onClick={() => setOpenAcceptModal(true)}
            >
              Accept
            </Button>
          ) : null}
          {isPrimaryPackage &&
          (reportStatus === ReportStatus.Complete ||
            reportStatus === ReportStatus.Accepted) ? (
            <Button
              color="primary"
              size="large"
              className="blackBtn"
              sx={{
                backgroundColor: "red",
                color: "white",
                fontSize: "14px",
                lineHeight: "16px",
                padding: "10px 15px",
              }}
              onClick={() => setOpenRejectModal(true)}
            >
              Reject
            </Button>
          ) : null}

          {reportStatus === ReportStatus.Complete ||
          reportStatus === ReportStatus.Accepted ? (
            <Button
              color="primary"
              size="large"
              onClick={() => handleDownloadReport(subPackage.id, reportId)}
              className="blackBtn"
              sx={{
                color: "white",
                fontSize: "14px",
                lineHeight: "16px",
                padding: "10px 15px",
              }}
            >
              Download
            </Button>
          ) : null}

          {reportStatus === ReportStatus.Overdue ? (
            <CoolDownButton
              cooldownDuration={5000}
              onResend={() => {
                sendReportReminder(subPackage.id, reportId);
              }}
              title={"Send Reminder"}
            />
          ) : null}
        </Stack>
      </TableCell>
      {isPrimaryPackage && reportSubmission ? (
        <RejectReportDialog
          open={openRejectModal}
          reportSubmission={reportSubmission}
          onCancel={(): void => {
            setOpenRejectModal(false);
          }}
          onConfirm={async (
            reportSubmission: ReportSubmissionLineItem,
            rejectionMessage: string,
          ): Promise<void> => {
            setOpenRejectModal(false);
            await handleRevertReport(reportSubmission, rejectionMessage);
          }}
        />
      ) : null}
      {isPrimaryPackage && reportSubmission ? (
        <AcceptReportDialog
          open={openAcceptModal}
          reportSubmission={reportSubmission}
          onCancel={(): void => {
            setOpenAcceptModal(false);
          }}
          onConfirm={async (
            reportSubmission: ReportSubmissionLineItem,
          ): Promise<void> => {
            setOpenAcceptModal(false);
            await handleAcceptReport(reportSubmission);
          }}
        />
      ) : null}
    </TableRow>
  );
};
