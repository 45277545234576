import React, { useState } from "react";
import { useProject } from "@hooks/crud/project/useProject";
import { createSearchParams, useNavigate } from "react-router-dom";
import * as RouteHelper from "@utils/routes";
import { ProjectTable } from "@stories/organisms/Tables/ProjectTable";
import {
  ProjectDetailLineItem,
  ProjectStatus,
} from "social-pro-common/interfaces/project";
import { useProfileContext } from "@hooks/context/useProfileContext";
import {
  Grid,
  Skeleton,
  Typography,
  Box,
  TableBody,
  Table,
  Stack,
} from "@mui/material";
import "./ListProjectStyles.css";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import ProjectEditModal from "@stories/organisms/ProjectEditModal/ProjectEditModal";
import { ListProjectsTabs } from "@stories/molecules/ListProjectsTabs/ListProjectsTabs";
import { useAuthContext } from "@hooks/context/useAuthContext";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { QuickAction } from "@stories/molecules/QuickAction/QuickAction";
import { useProjectContext } from "@hooks/context/useProjectContext";
import { Package } from "lucide-react";
import {
  daysUntil,
  getNumberOfDaysUntilNextReport,
  stringToDate,
} from "social-pro-common/utils/date";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import Groups2Icon from "@mui/icons-material/Groups2Outlined";
import { PageHeader } from "@stories/molecules/PageHeader/PageHeader";
import { AccordianPanel } from "@stories/atoms/AccordianPanel/AccordianPanel";
import { PackageType } from "social-pro-common/entities/contractorPackage";

export const ListProjects = (): JSX.Element => {
  const navigate = useNavigate();

  const { isContractor } = useAuthContext();
  const { isAuthProfileLoading, userOrganisation, userProfile } =
    useProfileContext();

  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState<string>();

  if (!userProfile) {
    throw new Error("No user profile to list projects");
  }

  const [value, setValue] = useState<ProjectStatus>(ProjectStatus.Active);

  const handleChange = (
    event: React.SyntheticEvent,
    newValue?: ProjectStatus,
  ) => {
    if (newValue) {
      setValue(newValue);
    }
  };

  const handleGoToTeams = () => {
    navigate({
      pathname: RouteHelper.users,
      search: createSearchParams({
        tab: "permissions",
      }).toString(),
    });
  };

  const {
    acceptPackage,
    createProject,
    isProjectLoading,
    projects,
    updateProject,
    updateProjectDetail,
  } = useProject(userProfile, undefined, value === ProjectStatus.Archived);

  const handleViewProject = (project: ProjectDetailLineItem) => {
    if (project.packageType === PackageType.OneTime) {
      navigate(
        RouteHelper.wizardOneTime(project.id, project.contractorPackageId),
      );
    } else {
      navigate(
        RouteHelper.contractorDashboard(
          project.id,
          project.contractorPackageId,
        ),
      );
    }
  };

  const handleArchiveProject = (project: ProjectDetailLineItem) => {
    updateProjectDetail({ ...project, archived: !project.archived });
  };

  const handleEditProject = async (projectId: string) => {
    setSelectedProjectId(projectId);
    setOpenEditModal(true);
  };

  const handleAcceptPackage = async (
    projectId: string,
    contractorPackageId: string,
  ) => {
    await acceptPackage(projectId, contractorPackageId);
  };

  const handleOpeEditModal = () => {
    setSelectedProjectId(undefined);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const filteredProjects = projects.sort((a, b) => {
    return a.created.getTime() - b.created.getTime();
  });

  const loading = isAuthProfileLoading || isProjectLoading;

  const { selectedProject } = useProjectContext();

  const filteredUpcomingReports = projects?.filter((p) => {
    return (
      !p.archived &&
      stringToDate(p.nextReportId) < stringToDate(p.projectEndDate)
    );
  });

  const numberOfDaysForPackageSubmission =
    getNumberOfDaysUntilNextReport(selectedProject);
  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minHeight: "100vh !important",
          padding: "0",
        }}
      >
        <Grid
          item
          md={9}
          sx={{
            margin: "0",
            marginBottom: "0",
            width: "100%",
          }}
        >
          <PageHeader
            loading={loading}
            title="Projects"
            subTitle={userOrganisation?.organisationName}
          />
          <Grid
            container
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              padding: "20px 40px 10px",
            }}
          >
            <Grid
              item
              xs={12}
              md={2}
              sx={{
                padding: "0 !important",
                textAlign: "left",
              }}
            >
              <ListProjectsTabs
                isLoading={loading}
                value={value}
                handleChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={10}
              md={10}
              sx={{
                padding: "0 !important",
                textAlign: "right",
              }}
            >
              <Stack
                direction="row"
                spacing={2}
                alignContent={"flex-end"}
                justifyContent={"flex-end"}
              >
                {userProfile.isAdmin ? (
                  <StyledButton
                    id={"manage-teams-button"}
                    className="blackBtn grey-outline-btn"
                    loading={loading}
                    variant="contained"
                    startIcon={<Groups2Icon />}
                    onClick={handleGoToTeams}
                  >
                    Manage Permissions
                  </StyledButton>
                ) : null}
                {isContractor && userProfile.isAdmin ? (
                  <StyledButton
                    data-test-id={"add-project-button"}
                    className="blackBtn"
                    loading={loading}
                    variant="contained"
                    disabled={!userProfile.isAdmin}
                    startIcon={<AddCircleIcon />}
                    onClick={handleOpeEditModal}
                  >
                    Add Project
                  </StyledButton>
                ) : null}
              </Stack>
            </Grid>
          </Grid>

          <ProjectTable
            projects={filteredProjects}
            loading={loading}
            archived={value}
            isContractor={isContractor}
            userProfile={userProfile}
            handleViewProject={handleViewProject}
            handleEditProject={handleEditProject}
            handleAcceptPackage={handleAcceptPackage}
            handleArchiveProject={handleArchiveProject}
            handleOpeEditModal={handleOpeEditModal}
          />
          {openEditModal && userOrganisation ? (
            <ProjectEditModal
              open={openEditModal}
              userProfile={userProfile}
              projectId={selectedProjectId}
              userOrganisation={userOrganisation}
              handleClose={handleCloseEditModal}
              isLoading={loading}
              createProject={createProject}
              updateProject={updateProject}
            />
          ) : null}
        </Grid>
        <Grid
          md={3}
          item
          sx={{
            display: "flex",
            justifyContent: "space-between",
            minHeight: "100vh !important",
          }}
        >
          <Box
            sx={{
              background: "#E9F0F5",
              padding: "85px 16px",
              width: "100%",
            }}
          >
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                height: "100px",
                justifyContent: "center",
                margin: "0 auto 20px",
                minWidth: "100px",
              }}
            >
              {loading ? (
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  width={100}
                  height={100}
                >
                  <img
                    width={100}
                    height={100}
                    alt="companylogo"
                    style={{
                      maxHeight: "100%",
                      maxWidth: "100%",
                      verticalAlign: "top",
                    }}
                  />
                </Skeleton>
              ) : (
                <img
                  src={userOrganisation?.organisationLogo}
                  alt="companylogo"
                  style={{
                    maxHeight: "100%",
                    maxWidth: "100%",
                    verticalAlign: "top",
                  }}
                />
              )}
            </Box>
            {loading ? (
              <Skeleton sx={{ margin: "auto" }}>
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "24px",
                    fontWeight: "700",
                    textAlign: "center",
                  }}
                >
                  Organisation name
                </Typography>
              </Skeleton>
            ) : (
              <Typography
                data-test-id="organisation-name"
                sx={{
                  color: "black",
                  fontSize: "24px",
                  fontWeight: "700",
                  textAlign: "center",
                }}
              >
                {userOrganisation?.organisationName}
              </Typography>
            )}
            {loading ? (
              <Skeleton sx={{ margin: "auto" }}>
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "16px",
                    fontWeight: "400",
                    margin: "10px 0",
                    opacity: "0.5",
                    textAlign: "center",
                  }}
                >
                  Organisation name
                </Typography>
              </Skeleton>
            ) : (
              <Typography
                data-test-id="organisation-location"
                sx={{
                  color: "black",
                  fontSize: "16px",
                  fontWeight: "400",
                  margin: "10px 0",
                  opacity: "0.5",
                  textAlign: "center",
                }}
              >
                {`${userOrganisation?.contactInfo.city}, ${userOrganisation?.contactInfo.state}`}
              </Typography>
            )}
            {value === ProjectStatus.Active ? (
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                  justifyContent: "left",
                  padding: "60px 0 0",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                {loading ? (
                  <Skeleton>
                    <AccordianPanel label="Package Reports">
                      <div></div>
                    </AccordianPanel>
                  </Skeleton>
                ) : (
                  <AccordianPanel label="Upcoming Reports">
                    {!loading && filteredUpcomingReports.length == 0 ? (
                      <Table>
                        <TableBody>
                          <NoRows title={"Upcoming Reports"} colSpan={6} />
                        </TableBody>
                      </Table>
                    ) : undefined}
                    {!loading &&
                      filteredUpcomingReports.map((project, index) => {
                        const daysUntilNextReport = daysUntil(
                          stringToDate(project.nextReportId),
                        );
                        return (
                          <QuickAction
                            backgroundColor="#f2f7f9"
                            key={index}
                            data-test-id="upcoming-reports-quick-action"
                            isLoading={loading}
                            title={`Submit package report for ${project.projectName}`}
                            overdue={daysUntilNextReport < 0}
                            message={`Due in ${daysUntilNextReport} days`}
                            icon={<Package color={"#000"} />}
                            action={() => {
                              navigate(
                                RouteHelper.contractorPackageReports(
                                  project?.id,
                                  project.contractorPackageId,
                                ),
                              );
                            }}
                          />
                        );
                      })}
                    {loading ? (
                      <>
                        <QuickAction
                          backgroundColor="transparent"
                          isLoading={loading}
                          title={"Submit package report"}
                          overdue={numberOfDaysForPackageSubmission < 0}
                          message={`Due in ${numberOfDaysForPackageSubmission} days`}
                          icon={<Package color={"#000"} />}
                          action={() => {
                            new Error("Not implemented");
                          }}
                        />
                        <QuickAction
                          backgroundColor="transparent"
                          isLoading={loading}
                          title={"Submit package report"}
                          overdue={numberOfDaysForPackageSubmission < 0}
                          message={`Due in ${numberOfDaysForPackageSubmission} days`}
                          icon={<Package color={"#000"} />}
                          action={() => {
                            new Error("Not implemented");
                          }}
                        />
                        <QuickAction
                          backgroundColor="transparent"
                          isLoading={loading}
                          title={"Submit package report"}
                          overdue={numberOfDaysForPackageSubmission < 0}
                          message={`Due in ${numberOfDaysForPackageSubmission} days`}
                          icon={<Package color={"#000"} />}
                          action={() => {
                            new Error("Not implemented");
                          }}
                        />
                      </>
                    ) : undefined}
                  </AccordianPanel>
                )}
              </div>
            ) : null}
          </Box>
        </Grid>
      </Grid>
      {/* <TestModal /> */}
    </>
  );
};
