import { useProjectContext } from "@hooks/context/useProjectContext";
import { useProjectReportSustainabilitySubmission } from "@hooks/crud/projectReportSustainabilitySubmission/useProjectReportSustainabilitySubmission";
import { Grid } from "@mui/material";
import { PageHeader } from "@stories/molecules/PageHeader/PageHeader";
import { useEffect, useState } from "react";
import { ReportStatus } from "social-pro-common/entities/projectReportSubmission";
import * as RouteHelper from "@utils/routes";
import { useProfileContext } from "@hooks/context/useProfileContext";
import { useNavigate } from "react-router-dom";
import usePdf from "@hooks/crud/downloadFiles/usePdf";
import { PdfDownlodDialog } from "@stories/molecules/PdfDownlodDialog/PdfDownlodDialog";
import NoPackagesModal from "../ProjectSocialReports/NoPackagesModal";
import { ReportColumn } from "@stories/organisms/ReportColumn/ReportColumn";

export const ProjectSustainabilityReports = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const { isAuthProfileLoading, userOrganisation } = useProfileContext();

  const {
    contractorPackage,
    isPrimaryPackage,
    isProjectLoading,
    selectedProject,
  } = useProjectContext();

  const { downloadProgress, downloadStep, getPdfInfo } = usePdf();

  const { isProjectReportLoading, projectReportSubmissions } =
    useProjectReportSustainabilitySubmission(selectedProject?.id);

  const loading =
    isAuthProfileLoading || isProjectLoading || isProjectReportLoading;

  const orderedSubmissions = projectReportSubmissions.slice().reverse();

  const upcomingReports = orderedSubmissions.filter(
    (r) => r.reportStatus === ReportStatus.Upcoming,
  );

  const currentReports = orderedSubmissions.filter(
    (r) =>
      r.reportStatus === ReportStatus.Overdue ||
      r.reportStatus === ReportStatus.ToSubmit,
  );

  const submittedReports = orderedSubmissions.filter(
    (r) =>
      r.reportStatus === ReportStatus.Complete ||
      r.reportStatus === ReportStatus.Accepted,
  );

  const handleDownloadReport = (reportId: string, reportTitle: string) => {
    if (selectedProject) {
      getPdfInfo(selectedProject.id, reportId, reportTitle, undefined, true);
    }
  };
  const handlViewReport = (reportMonthId: string) => {
    if (selectedProject && contractorPackage) {
      navigate(
        RouteHelper.projectSustainabilityWizard(
          selectedProject.id,
          contractorPackage.id,
          reportMonthId,
        ),
      );
    }
  };

  useEffect(() => {
    if (!loading && orderedSubmissions.length === 0) {
      setShowModal(true);
    }
  }, [loading]);

  return (
    <>
      <Grid
        container
        sx={{
          margin: "0",
          marginBottom: "0",
          width: "100%",
        }}
      >
        <PageHeader
          loading={loading}
          title="Project Sustainability Reports"
          subTitle={userOrganisation?.organisationName}
        />

        <Grid
          item
          sx={{
            display: "flex",
            minHeight: "90vh",
            padding: "20px 30px 30px !important",
            width: "100%",
          }}
        >
          <ReportColumn
            dataTestId="upcoming-reports"
            loading={loading}
            title={"Upcoming"}
            subTitle={"Reports due in future months"}
            dueDate={selectedProject?.reportingDueDate || 0}
            reports={upcomingReports}
            isPrimaryPackage={false}
            handlViewReport={handlViewReport}
            handleDownloadReport={handleDownloadReport}
          />
          <ReportColumn
            dataTestId="current-reports"
            loading={loading}
            title={"Current"}
            subTitle={"Reports that require urgent action"}
            isPrimaryPackage={isPrimaryPackage}
            dueDate={selectedProject?.reportingDueDate || 0}
            reports={currentReports}
            handlViewReport={handlViewReport}
            handleDownloadReport={handleDownloadReport}
          />
          <ReportColumn
            dataTestId="completed-reports"
            loading={loading}
            title={"Complete"}
            subTitle={"Reports previously submitted"}
            dueDate={selectedProject?.reportingDueDate || 0}
            reports={submittedReports}
            isPrimaryPackage={isPrimaryPackage}
            handlViewReport={handlViewReport}
            handleDownloadReport={handleDownloadReport}
          />
        </Grid>
        <PdfDownlodDialog
          downloadStep={downloadStep}
          total={downloadProgress}
          title={"Project Report Export"}
        />
        {!loading && showModal ? (
          <NoPackagesModal
            handleClose={() => {
              setShowModal(false);
            }}
          />
        ) : null}
      </Grid>
    </>
  );
};
