"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stringImportNatureOfExpenditure = exports.stringImportToEntityType = exports.stringImportState = exports.stringImportToCertification = exports.stringImportToPurchaserType = exports.createDefaultSocialSpend = exports.socialSpendServiceCategoryToString = exports.socialSpendCertificateToString = exports.socialSpendPurchaserTypeToString = exports.socialSpendEntityTypeToSocialFactor = exports.socialSpendEntityTypeToString = void 0;
var uuid_1 = require("uuid");
var packageSocialSpendCommitment_1 = require("../packageSocialSpendCommitment");
var socialSpend_1 = require("../../entities/socialSpend");
var date_1 = require("../../utils/date");
var socialSpendEntityTypeToString = function (socialSpendEntityType) {
    switch (socialSpendEntityType) {
        case socialSpend_1.SocialSpendEntityType.None:
            return "None";
        case socialSpend_1.SocialSpendEntityType.SocialEnterprise:
            return "Social Enterprise";
        case socialSpend_1.SocialSpendEntityType.DisabilityEnterprise:
            return "Australian Disability Enterprise (ADE)";
        case socialSpend_1.SocialSpendEntityType.IndigenousEnterprise:
            return "Aboriginal Owned Business";
    }
};
exports.socialSpendEntityTypeToString = socialSpendEntityTypeToString;
var socialSpendEntityTypeToSocialFactor = function (socialSpendEntityTypes) {
    var socialFactors = [];
    if (socialSpendEntityTypes.includes(socialSpend_1.SocialSpendEntityType.SocialEnterprise) ||
        socialSpendEntityTypes.includes(socialSpend_1.SocialSpendEntityType.DisabilityEnterprise)) {
        socialFactors.push(packageSocialSpendCommitment_1.SocialCommitmentTypeSpend.SocialDisabilityEnterpriseSpend);
    }
    if (socialSpendEntityTypes.includes(socialSpend_1.SocialSpendEntityType.IndigenousEnterprise)) {
        socialFactors.push(packageSocialSpendCommitment_1.SocialCommitmentTypeSpend.AboriginalTorresStraitIslanderBusinessSpend);
    }
    return socialFactors;
};
exports.socialSpendEntityTypeToSocialFactor = socialSpendEntityTypeToSocialFactor;
var socialSpendPurchaserTypeToString = function (socialSpendPurchaserType) {
    switch (socialSpendPurchaserType) {
        case socialSpend_1.PurchaserType.Direct:
            return "Direct";
        case socialSpend_1.PurchaserType.Indirect:
            return "Indirect";
    }
};
exports.socialSpendPurchaserTypeToString = socialSpendPurchaserTypeToString;
var socialSpendCertificateToString = function (socialSpendCertificate) {
    switch (socialSpendCertificate) {
        case socialSpend_1.SocialSpendCertificate.AustralianCharitiesAndNotForProfitsCommision:
            return "Australian Charities and Not-for-profits Commision";
        case socialSpend_1.SocialSpendCertificate.AustralianNetworkOnDisability:
            return "Australian Network on Disability";
        case socialSpend_1.SocialSpendCertificate.DepartmentOfEducation:
            return "Department of Education";
        case socialSpend_1.SocialSpendCertificate.Kinaway:
            return "Kinaway";
        case socialSpend_1.SocialSpendCertificate.OfficeOfRegistrarOfIndigenousCorporations:
            return "Office of Registrar of Indigenous Corporations";
        case socialSpend_1.SocialSpendCertificate.SocialTraders:
            return "Social Traders";
        case socialSpend_1.SocialSpendCertificate.SupplyNationCertified:
            return "Supply Nation Certified";
        case socialSpend_1.SocialSpendCertificate.SupplyNationRegistered:
            return "Supply Nation Registered";
    }
};
exports.socialSpendCertificateToString = socialSpendCertificateToString;
var socialSpendServiceCategoryToString = function (socialSpendServiceCategory) {
    switch (socialSpendServiceCategory) {
        case socialSpend_1.SocialSpendServiceCategory.AdvertisingAndMedia:
            return "Advertising & media";
        case socialSpend_1.SocialSpendServiceCategory.ArchitecturePlanningAndDesign:
            return "Architecture, Planning & Design";
        case socialSpend_1.SocialSpendServiceCategory.BuildingProductsAndmaterials:
            return "Building products & materials";
        case socialSpend_1.SocialSpendServiceCategory.BuildingTradeRepairsAndMaintenance:
            return "Building Trade, Repairs & Maintenance";
        case socialSpend_1.SocialSpendServiceCategory.BusinessAndAdministrativeServices:
            return "Business & Administrative Services";
        case socialSpend_1.SocialSpendServiceCategory.CateringAndHospitality:
            return "Catering & Hospitality";
        case socialSpend_1.SocialSpendServiceCategory.CivilConstruction:
            return "Civil Construction";
        case socialSpend_1.SocialSpendServiceCategory.CleaningServicesAndEquipmentAndSupplies:
            return "Cleaning Services & Equipment & Supplies";
        case socialSpend_1.SocialSpendServiceCategory.ClothingAndSafetyEquipment:
            return "Clothing & Safety Equipment";
        case socialSpend_1.SocialSpendServiceCategory.CommunicationsPromotionalMaterials:
            return "Communications / promotional materials";
        case socialSpend_1.SocialSpendServiceCategory.ConstructionPlantWetAndDryAndToolHire:
            return "Construction Plant (wet and dry) & Tool Hire";
        case socialSpend_1.SocialSpendServiceCategory.CraneHire:
            return "Crane hire";
        case socialSpend_1.SocialSpendServiceCategory.CulturalHeritageManagement:
            return "Cultural Heritage Management";
        case socialSpend_1.SocialSpendServiceCategory.ElectricalContractors:
            return "Electrical contractors";
        case socialSpend_1.SocialSpendServiceCategory.EmploymentDirect:
            return "Employment (direct)";
        case socialSpend_1.SocialSpendServiceCategory.EmploymentLabourHire:
            return "Employment (labour hire)";
        case socialSpend_1.SocialSpendServiceCategory.EngineerResearchTechServices:
            return "Engineer, Research, Tech services";
        case socialSpend_1.SocialSpendServiceCategory.EventsVenueHire:
            return "Events / Venue Hire";
        case socialSpend_1.SocialSpendServiceCategory.FinancialAndInsuranceServices:
            return "Financial and Insurance Services";
        case socialSpend_1.SocialSpendServiceCategory.FleetHireAndCleaning:
            return "Fleet (hire and cleaning)";
        case socialSpend_1.SocialSpendServiceCategory.FleetServicingAndaccessories:
            return "Fleet servicing & accessories";
        case socialSpend_1.SocialSpendServiceCategory.FoodAndBeverageProducts:
            return "Food & Beverage Products";
        case socialSpend_1.SocialSpendServiceCategory.FurnitureAndFurnishings:
            return "Furniture & Furnishings";
        case socialSpend_1.SocialSpendServiceCategory.GiftsOrrewards:
            return "Gifts or rewards";
        case socialSpend_1.SocialSpendServiceCategory.HealthcareServices:
            return "Healthcare services";
        case socialSpend_1.SocialSpendServiceCategory.HorticultureAndarboriculture:
            return "Horticulture & arboriculture";
        case socialSpend_1.SocialSpendServiceCategory.HumanResourceServicesAndrecruitment:
            return "Human resource services & recruitment";
        case socialSpend_1.SocialSpendServiceCategory.IndustrialProductionAndmanufacture:
            return "Industrial Production & manufacture";
        case socialSpend_1.SocialSpendServiceCategory.ITAndTelecomms:
            return "IT & Telecomms";
        case socialSpend_1.SocialSpendServiceCategory.LandscapingAndArchitecturalSupplyAndConstruction:
            return "Landscaping and architectural (supply and construction)";
        case socialSpend_1.SocialSpendServiceCategory.LegalServices:
            return "Legal Services";
        case socialSpend_1.SocialSpendServiceCategory.LibraryServices:
            return "Library Services";
        case socialSpend_1.SocialSpendServiceCategory.MarketingAndCommunications:
            return "Marketing and Communications";
        case socialSpend_1.SocialSpendServiceCategory.MechanicalServices:
            return "Mechanical Services";
        case socialSpend_1.SocialSpendServiceCategory.MerchandiseAndPersonalProducts:
            return "Merchandise and Personal Products";
        case socialSpend_1.SocialSpendServiceCategory.MiningOilAndGas:
            return "Mining, Oil and Gas";
        case socialSpend_1.SocialSpendServiceCategory.MusicAndGamesAndToysAndArtsAndCraftsAndEducational:
            return "Music & Games & Toys & Arts & Crafts & Educational";
        case socialSpend_1.SocialSpendServiceCategory.OfficeConsumables:
            return "Office consumables";
        case socialSpend_1.SocialSpendServiceCategory.OfficeSuppliesAndservices:
            return "Office supplies & services";
        case socialSpend_1.SocialSpendServiceCategory.ParkingAndTrafficServicesAndEquipment:
            return "Parking & Traffic Services & Equipment";
        case socialSpend_1.SocialSpendServiceCategory.PavementConstruction:
            return "Pavement construction";
        case socialSpend_1.SocialSpendServiceCategory.PersonalAndDomesticServices:
            return "Personal & Domestic Services";
        case socialSpend_1.SocialSpendServiceCategory.PersonalProtectiveEquipmentPPE:
            return "Personal Protective Equipment (PPE)";
        case socialSpend_1.SocialSpendServiceCategory.PrecastConcrete:
            return "Precast concrete";
        case socialSpend_1.SocialSpendServiceCategory.PrintingAndDistributionServices:
            return "Printing and Distribution Services";
        case socialSpend_1.SocialSpendServiceCategory.ProfessionalConsultancyServices:
            return "Professional consultancy services";
        case socialSpend_1.SocialSpendServiceCategory.RealEstateAndpropertyServices:
            return "Real Estate & property Services";
        case socialSpend_1.SocialSpendServiceCategory.RenewableEnergy:
            return "Renewable Energy";
        case socialSpend_1.SocialSpendServiceCategory.SafetyBarriersAndBollards:
            return "Safety barriers and bollards";
        case socialSpend_1.SocialSpendServiceCategory.ScaffoldingAndaccess:
            return "Scaffolding & access";
        case socialSpend_1.SocialSpendServiceCategory.SecurityServicesAndEquipment:
            return "Security Services & Equipment";
        case socialSpend_1.SocialSpendServiceCategory.SignsAndBarriers:
            return "Signs & Barriers";
        case socialSpend_1.SocialSpendServiceCategory.SignsAndpavementMarkings:
            return "Signs & pavement markings";
        case socialSpend_1.SocialSpendServiceCategory.SiteFacilities:
            return "Site facilities";
        case socialSpend_1.SocialSpendServiceCategory.SportAndRecEquipmentAndServices:
            return "Sport & Rec Equipment & Services";
        case socialSpend_1.SocialSpendServiceCategory.StormwaterAndsubsurfaceDrainageSupplyOrinstallation:
            return "Stormwater & subsurface drainage (supply or installation)";
        case socialSpend_1.SocialSpendServiceCategory.StructuralSteelFabrication:
            return "Structural steel fabrication";
        case socialSpend_1.SocialSpendServiceCategory.Surveying:
            return "Surveying";
        case socialSpend_1.SocialSpendServiceCategory.TrafficManagement:
            return "Traffic management";
        case socialSpend_1.SocialSpendServiceCategory.TrafficServicesAndEquipment:
            return "Traffic Services & Equipment";
        case socialSpend_1.SocialSpendServiceCategory.TrainingAndEducationServices:
            return "Training & Education Services";
        case socialSpend_1.SocialSpendServiceCategory.TransportFreightAndLogistics:
            return "Transport, Freight & Logistics";
        case socialSpend_1.SocialSpendServiceCategory.TravelAndTourism:
            return "Travel & Tourism";
        case socialSpend_1.SocialSpendServiceCategory.UtilityServicesWorks:
            return "Utility services works";
        case socialSpend_1.SocialSpendServiceCategory.VenueHire:
            return "Venue hire";
        case socialSpend_1.SocialSpendServiceCategory.WasteManagement:
            return "Waste management";
        case socialSpend_1.SocialSpendServiceCategory.WasteManagementAndLandfill:
            return "Waste Management & Landfill";
        case socialSpend_1.SocialSpendServiceCategory.None:
            return "None";
    }
};
exports.socialSpendServiceCategoryToString = socialSpendServiceCategoryToString;
var createDefaultSocialSpend = function (projectId, contractorPackageId, reportId, userOrganisation) {
    var reportDate = (0, date_1.stringToDate)(reportId);
    return {
        abn: "",
        certifications: [],
        contractorPackageId: contractorPackageId,
        created: new Date(),
        entityType: socialSpend_1.SocialSpendEntityType.None,
        id: (0, uuid_1.v4)(),
        invoiceValue: 0,
        natureOfExpenditure: socialSpend_1.SocialSpendServiceCategory.None,
        organisationName: userOrganisation.organisationName,
        projectId: projectId,
        purchaserOrganisationAbn: userOrganisation.abn,
        purchaserOrganisationName: userOrganisation.organisationName,
        purchaseType: socialSpend_1.PurchaserType.Direct,
        registered: false,
        reportId: reportId,
        socialFactors: [],
        supplier: "",
        supplierCountry: "AU",
        supplierPostCode: "",
        supplierState: "VIC",
        transactionDate: reportDate,
        uri: "",
        victorianPostCode: "",
    };
};
exports.createDefaultSocialSpend = createDefaultSocialSpend;
var stringImportToPurchaserType = function (purchaserType) {
    switch (purchaserType.toLowerCase()) {
        default:
            return undefined;
        case "direct":
            return socialSpend_1.PurchaserType.Direct;
        case "indirect":
            return socialSpend_1.PurchaserType.Indirect;
    }
};
exports.stringImportToPurchaserType = stringImportToPurchaserType;
// Australian Charities and Not-for-profits Commission
// Australian Network on Disability
// Department of Education
// Kinaway
// Office of Registrar of Indigenous Corporations
// Social Traders
// Supply Nation Certified
// Supply Nation Registered
var stringImportToCertification = function (certification) {
    switch (certification.toLowerCase()) {
        default:
            return undefined;
        case "australian charities and not-for-profits commission":
            return socialSpend_1.SocialSpendCertificate.AustralianCharitiesAndNotForProfitsCommision;
        case "australian network on disability":
            return socialSpend_1.SocialSpendCertificate.AustralianNetworkOnDisability;
        case "department of education":
            return socialSpend_1.SocialSpendCertificate.DepartmentOfEducation;
        case "kinaway":
            return socialSpend_1.SocialSpendCertificate.Kinaway;
        case "office of registrar of indigenous corporations":
            return socialSpend_1.SocialSpendCertificate.OfficeOfRegistrarOfIndigenousCorporations;
        case "social traders":
            return socialSpend_1.SocialSpendCertificate.SocialTraders;
        case "supply nation certified":
            return socialSpend_1.SocialSpendCertificate.SupplyNationCertified;
        case "supply nation registered":
            return socialSpend_1.SocialSpendCertificate.SupplyNationRegistered;
    }
};
exports.stringImportToCertification = stringImportToCertification;
// Victoria
// New South Wales
// Queensland
// South Australia
// Western Australia
// Australian Capital Territory
// Tasmania
// Northern Territory
var stringImportState = function (state) {
    switch (state.toLowerCase()) {
        default:
            throw new Error("Invalid state");
        case "victoria":
            return "VIC";
        case "new south wales":
            return "NSW";
        case "queensland":
            return "QLD";
        case "south australia":
            return "SA";
        case "western australia":
            return "WA";
        case "australian capital territory":
            return "ACT";
        case "tasmania":
            return "TAS";
        case "northern territory":
            return "NT";
    }
};
exports.stringImportState = stringImportState;
// Social Enterprise
// Australian Disability Enterprise (ADE)
// Aboriginal Owned Business
var stringImportToEntityType = function (entityType) {
    switch (entityType.toLowerCase()) {
        default:
            return undefined;
        case "social enterprise":
            return socialSpend_1.SocialSpendEntityType.SocialEnterprise;
        case "australian disability enterprise (ade)":
            return socialSpend_1.SocialSpendEntityType.DisabilityEnterprise;
        case "aboriginal owned business":
            return socialSpend_1.SocialSpendEntityType.IndigenousEnterprise;
    }
};
exports.stringImportToEntityType = stringImportToEntityType;
var stringImportNatureOfExpenditure = function (entityType) {
    switch (entityType.toLowerCase()) {
        case "advertising and media":
            return socialSpend_1.SocialSpendServiceCategory.AdvertisingAndMedia;
        case "architecture, planning and design":
            return socialSpend_1.SocialSpendServiceCategory.ArchitecturePlanningAndDesign;
        case "building products and materials":
            return socialSpend_1.SocialSpendServiceCategory.BuildingProductsAndmaterials;
        case "building trade repairs and maintenance":
            return socialSpend_1.SocialSpendServiceCategory.BuildingTradeRepairsAndMaintenance;
        case "business and administrative services":
            return socialSpend_1.SocialSpendServiceCategory.BusinessAndAdministrativeServices;
        case "catering and hospitality":
            return socialSpend_1.SocialSpendServiceCategory.CateringAndHospitality;
        case "civil construction":
            return socialSpend_1.SocialSpendServiceCategory.CivilConstruction;
        case "cleaning services and equipment and supplies":
            return socialSpend_1.SocialSpendServiceCategory.CleaningServicesAndEquipmentAndSupplies;
        case "clothing and safety equipment":
            return socialSpend_1.SocialSpendServiceCategory.ClothingAndSafetyEquipment;
        case "communications and promotional materials":
            return socialSpend_1.SocialSpendServiceCategory.CommunicationsPromotionalMaterials;
        case "construction plant (wet and dry) and tool hire":
            return socialSpend_1.SocialSpendServiceCategory.ConstructionPlantWetAndDryAndToolHire;
        case "crane hire":
            return socialSpend_1.SocialSpendServiceCategory.CraneHire;
        case "cultural heritage management":
            return socialSpend_1.SocialSpendServiceCategory.CulturalHeritageManagement;
        case "electrical contractors":
            return socialSpend_1.SocialSpendServiceCategory.ElectricalContractors;
        case "employment direct":
            return socialSpend_1.SocialSpendServiceCategory.EmploymentDirect;
        case "employment labour hire":
            return socialSpend_1.SocialSpendServiceCategory.EmploymentLabourHire;
        case "engineer research & tech services":
            return socialSpend_1.SocialSpendServiceCategory.EngineerResearchTechServices;
        case "events & venue hire":
            return socialSpend_1.SocialSpendServiceCategory.EventsVenueHire;
        case "financial and insurance services":
            return socialSpend_1.SocialSpendServiceCategory.FinancialAndInsuranceServices;
        case "fleet hire and cleaning":
            return socialSpend_1.SocialSpendServiceCategory.FleetHireAndCleaning;
        case "fleet servicing and accessories":
            return socialSpend_1.SocialSpendServiceCategory.FleetServicingAndaccessories;
        case "food and beverage products":
            return socialSpend_1.SocialSpendServiceCategory.FoodAndBeverageProducts;
        case "furniture and furnishings":
            return socialSpend_1.SocialSpendServiceCategory.FurnitureAndFurnishings;
        case "gifts or rewards":
            return socialSpend_1.SocialSpendServiceCategory.GiftsOrrewards;
        case "healthcare services":
            return socialSpend_1.SocialSpendServiceCategory.HealthcareServices;
        case "horticulture and arboriculture":
            return socialSpend_1.SocialSpendServiceCategory.HorticultureAndarboriculture;
        case "human resource services and recruitment":
            return socialSpend_1.SocialSpendServiceCategory.HumanResourceServicesAndrecruitment;
        case "industrial production and manufacture":
            return socialSpend_1.SocialSpendServiceCategory.IndustrialProductionAndmanufacture;
        case "it and telecomms":
            return socialSpend_1.SocialSpendServiceCategory.ITAndTelecomms;
        case "landscaping and architectural supply and construction":
            return socialSpend_1.SocialSpendServiceCategory.LandscapingAndArchitecturalSupplyAndConstruction;
        case "legal services":
            return socialSpend_1.SocialSpendServiceCategory.LegalServices;
        case "library services":
            return socialSpend_1.SocialSpendServiceCategory.LibraryServices;
        case "marketing and communications":
            return socialSpend_1.SocialSpendServiceCategory.MarketingAndCommunications;
        case "mechanical services":
            return socialSpend_1.SocialSpendServiceCategory.MechanicalServices;
        case "merchandise and personal products":
            return socialSpend_1.SocialSpendServiceCategory.MerchandiseAndPersonalProducts;
        case "mining, oil and gas":
            return socialSpend_1.SocialSpendServiceCategory.MiningOilAndGas;
        case "music and games and toys and arts and crafts and educational":
            return socialSpend_1.SocialSpendServiceCategory.MusicAndGamesAndToysAndArtsAndCraftsAndEducational;
        case "office consumables":
            return socialSpend_1.SocialSpendServiceCategory.OfficeConsumables;
        case "office supplies and services":
            return socialSpend_1.SocialSpendServiceCategory.OfficeSuppliesAndservices;
        case "parking and traffic services and equipment":
            return socialSpend_1.SocialSpendServiceCategory.ParkingAndTrafficServicesAndEquipment;
        case "pavement construction":
            return socialSpend_1.SocialSpendServiceCategory.PavementConstruction;
        case "personal and domestic services":
            return socialSpend_1.SocialSpendServiceCategory.PersonalAndDomesticServices;
        case "personal protective equipment (ppe)":
            return socialSpend_1.SocialSpendServiceCategory.PersonalProtectiveEquipmentPPE;
        case "precast concrete":
            return socialSpend_1.SocialSpendServiceCategory.PrecastConcrete;
        case "printing and distribution services":
            return socialSpend_1.SocialSpendServiceCategory.PrintingAndDistributionServices;
        case "professional consultancy services":
            return socialSpend_1.SocialSpendServiceCategory.ProfessionalConsultancyServices;
        case "real estate and property services":
            return socialSpend_1.SocialSpendServiceCategory.RealEstateAndpropertyServices;
        case "renewable energy":
            return socialSpend_1.SocialSpendServiceCategory.RenewableEnergy;
        case "safety barriers and bollards":
            return socialSpend_1.SocialSpendServiceCategory.SafetyBarriersAndBollards;
        case "scaffolding and access":
            return socialSpend_1.SocialSpendServiceCategory.ScaffoldingAndaccess;
        case "security services and equipment":
            return socialSpend_1.SocialSpendServiceCategory.SecurityServicesAndEquipment;
        case "signs and barriers":
            return socialSpend_1.SocialSpendServiceCategory.SignsAndBarriers;
        case "signs and pavement markings":
            return socialSpend_1.SocialSpendServiceCategory.SignsAndpavementMarkings;
        case "site facilities":
            return socialSpend_1.SocialSpendServiceCategory.SiteFacilities;
        case "sport and rec equipment and services":
            return socialSpend_1.SocialSpendServiceCategory.SportAndRecEquipmentAndServices;
        case "stormwater and subsurface drainage supply or installation":
            return socialSpend_1.SocialSpendServiceCategory.StormwaterAndsubsurfaceDrainageSupplyOrinstallation;
        case "structural steel fabrication":
            return socialSpend_1.SocialSpendServiceCategory.StructuralSteelFabrication;
        case "surveying":
            return socialSpend_1.SocialSpendServiceCategory.Surveying;
        case "traffic management":
            return socialSpend_1.SocialSpendServiceCategory.TrafficManagement;
        case "traffic services and equipment":
            return socialSpend_1.SocialSpendServiceCategory.TrafficServicesAndEquipment;
        case "training and education services":
            return socialSpend_1.SocialSpendServiceCategory.TrainingAndEducationServices;
        case "transport - freight and logistics":
            return socialSpend_1.SocialSpendServiceCategory.TransportFreightAndLogistics;
        case "travel and tourism":
            return socialSpend_1.SocialSpendServiceCategory.TravelAndTourism;
        case "utility services works":
            return socialSpend_1.SocialSpendServiceCategory.UtilityServicesWorks;
        case "venue hire":
            return socialSpend_1.SocialSpendServiceCategory.VenueHire;
        case "waste management":
            return socialSpend_1.SocialSpendServiceCategory.WasteManagement;
        case "waste management and landfill":
            return socialSpend_1.SocialSpendServiceCategory.WasteManagementAndLandfill;
        default:
        case "none":
            return socialSpend_1.SocialSpendServiceCategory.None;
    }
};
exports.stringImportNatureOfExpenditure = stringImportNatureOfExpenditure;
