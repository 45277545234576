import { useProjectContext } from "@hooks/context/useProjectContext";
import { Grid, Stack } from "@mui/material";
import { WizardSteps } from "@stories/molecules/WizardSteps/WizardSteps";
import { useEffect, useState } from "react";
import { LabourHours } from "../../organisms/LabourHours/LabourHours";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useProfileContext } from "@hooks/context/useProfileContext";
import { SocialSpends } from "@stories/organisms/SocialSpend/SocialSpend";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { usePackageComments } from "@hooks/crud/packageComments/usePackageComments";
import { PackageLidpSubmissionForm } from "@stories/molecules/PackageLidpSubmissionForm/PackageLidpSubmissionForm";
import { PackageCommentType } from "social-pro-common/entities/packageComment";
import { useContractorPackageDetails } from "@hooks/crud/contractorPackages/useContractorPackageDetails";
import * as RouteHelper from "@utils/routes";
import { useReportDocument } from "@hooks/crud/reportDocument/useReportDocument";
import { PackageType } from "social-pro-common/entities/contractorPackage";
import { useOrganisation } from "@hooks/crud/organisation/useOrganisation";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";

export const ReadOnlyReportSocial = () => {
  const { contractorPackageId, packageId, projectId, reportId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  if (!reportId) {
    throw Error("Not report number");
  }
  if (!contractorPackageId) {
    throw Error("Not contractorPackageId");
  }

  if (!packageId) {
    throw Error("Not packageId");
  }

  const handlOnBack = () => {
    if (projectId && packageId && reportId) {
      const redirect = searchParams.get("redirect");

      if (redirect) {
        navigate(redirect);
      } else {
        navigate({
          pathname: RouteHelper.contractorPackage(
            projectId,
            contractorPackageId,
            packageId,
          ),
          search: createSearchParams({
            tab: "reports",
          }).toString(),
        });
      }
    }
  };

  const [reportOrganisation, setReportOrganisation] =
    useState<OrganisationLineItem>();

  const { isAuthProfileLoading } = useProfileContext();

  const { contractorPackageDetails, isContractorPackageLoading } =
    useContractorPackageDetails(packageId);

  const { getOrganisation, isOrganisationLoading } = useOrganisation();

  const {
    createReportDocument,
    isReportDocumentsLoading,
    reportDocuments,
    updateReportDocument,
  } = useReportDocument(packageId, reportId);

  const { isPrimaryPackage, isProjectLoading, selectedProject } =
    useProjectContext();

  const { comments } = usePackageComments(
    selectedProject?.id,
    reportId,
    contractorPackageDetails?.id,
  );

  const [activeStep, setActiveStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [commentMap, setCommentMap] = useState(
    new Map<PackageCommentType, string>(),
  );

  useEffect(() => {
    if (comments) {
      const commentMap = new Map<PackageCommentType, string>();
      comments.forEach((comment) => {
        commentMap.set(comment.commentType, comment.comment);
      });
      setCommentMap(commentMap);
    }
  }, [comments]);

  useEffect(() => {
    if (contractorPackageDetails) {
      const getOrganisationForReport = async () => {
        const organisation = await getOrganisation(
          contractorPackageDetails.contractorId,
        );
        if (organisation) {
          setReportOrganisation(organisation);
        }
      };
      getOrganisationForReport();
    }
  }, [contractorPackageDetails]);

  const updateComment = async () => {
    console.log("Not implemented");
  };

  const loading =
    (isAuthProfileLoading ||
      isProjectLoading ||
      isContractorPackageLoading ||
      isOrganisationLoading ||
      isReportDocumentsLoading) &&
    !isSubmitting;

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = async () => {
    setActiveStep(activeStep - 1);
  };

  const wizardComponents = [];
  if (contractorPackageDetails) {
    if (contractorPackageDetails?.commitmentsHours?.length) {
      wizardComponents.push(
        <LabourHours
          isLoading={loading}
          project={selectedProject}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          userOrganisation={reportOrganisation}
          contractorPackageId={packageId}
          commentMap={commentMap}
          reportId={reportId}
          readOnly={true}
          reportDocuments={reportDocuments}
          createReportDocument={createReportDocument}
          updateReportDocument={updateReportDocument}
          updateComment={updateComment}
          handlOnBack={handlOnBack}
        />,
      );
    }
    if (contractorPackageDetails?.commitmentsSpend?.length) {
      wizardComponents.push(
        <SocialSpends
          isLoading={loading}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          selectedProject={selectedProject}
          contractorPackageId={packageId}
          reportId={reportId}
          commentMap={commentMap}
          userOrganisation={reportOrganisation}
          readOnly={true}
          updateComment={updateComment}
          handlOnBack={handlOnBack}
        />,
      );
    }
    if (selectedProject?.localContents?.length) {
      wizardComponents.push(
        <PackageLidpSubmissionForm
          isLoading={loading}
          isSubmitting={isSubmitting}
          project={selectedProject}
          contractorPackageId={packageId}
          isPrimaryPackage={isPrimaryPackage}
          reportId={reportId}
          readOnly={true}
          commentMap={commentMap}
          packageValue={
            isPrimaryPackage
              ? selectedProject.financial.totalValue || 0
              : contractorPackageDetails.financial.totalValue || 0
          }
          packageType={
            contractorPackageDetails.packageType || PackageType.Other
          }
          updateComment={updateComment}
          handlOnBack={handlOnBack}
        />,
      );
    }
  }

  const selectedWizardStep = wizardComponents[activeStep];

  return (
    <>
      <Grid container spacing={2} sx={{ margin: "0", padding: "30px 20px" }}>
        <Grid item xs={12} md={12} className="steps">
          <WizardSteps
            activeStep={activeStep}
            loading={loading}
            project={selectedProject}
            contractorPackage={contractorPackageDetails}
            readOnly={true}
          />
        </Grid>
      </Grid>
      {selectedProject && contractorPackageDetails
        ? selectedWizardStep
        : undefined}
      {!selectedProject || !contractorPackageDetails ? (
        <LabourHours
          isLoading={true}
          project={selectedProject}
          isSubmitting={isSubmitting}
          contractorPackageId={packageId}
          setIsSubmitting={setIsSubmitting}
          commentMap={commentMap}
          reportId={reportId}
          updateComment={updateComment}
          readOnly={true}
          reportDocuments={reportDocuments}
          createReportDocument={createReportDocument}
          updateReportDocument={updateReportDocument}
          handlOnBack={handlOnBack}
        />
      ) : undefined}
      <Grid
        container
        sx={{
          display: "flex !important",
          justifyContent: "space-between",
          padding: "0 40px 80px",
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            justifyContent: "end",
            padding: "0 !important",
            textAlign: "right",
          }}
        >
          <Stack direction="row" spacing={1}>
            {activeStep > 0 ? (
              <StyledButton
                loading={loading}
                onClick={handleBack}
                variant="outlined"
              >
                Back
              </StyledButton>
            ) : null}

            {activeStep < wizardComponents.length - 1 ? (
              <StyledButton loading={loading} onClick={handleNext}>
                Next
              </StyledButton>
            ) : null}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
