import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { ViewEmployeeTable } from "@stories/molecules/ViewEmployeeTable/ViewEmployeeTable";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { useState } from "react";
import { CircularProgressWithLabel } from "@stories/molecules/CircularProgressWithLabel/CircularProgressWithLabel";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { downloadFile } from "@hooks/utils/useUpload";
import { toast } from "react-toastify";
import { getDocumentName } from "social-pro-common/utils/string";
import DownloadIcon from "@mui/icons-material/Download";
import { useProjectAllAssignedEmployee } from "@hooks/crud/projectEmployee/useProjectAllAssginedEmployee";

interface SummaryDetailsProps {
  isLoading: boolean;
  project?: ProjectLineItem;
  contractorOrganisation?: OrganisationLineItem;
  contractorPackage?: ContractorPackageLineItem;
}
export const SummaryDetails = ({
  contractorOrganisation,
  contractorPackage,
  isLoading,
  project,
}: SummaryDetailsProps) => {
  const [page, setPage] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);

  const { employeeCount, employees, isEmployeeLoading } =
    useProjectAllAssignedEmployee(
      contractorOrganisation?.id,
      project?.id,
      undefined,
      page,
    );

  const onDownload = async (uri: string, policyName: string) => {
    try {
      setDownloadProgress(0);
      setIsDownloading(true);
      await downloadFile(uri, policyName, (progress: any) => {
        const total = (progress.loaded / progress.total) * 100;

        setDownloadProgress(total);
        if (total >= 100) {
          setIsDownloading(false);
        }
      });

      toast.success("Downloaded successfully");
    } catch (e: any) {
      toast.error("Failed to download file");
      setIsDownloading(false);
    }
  };

  const loading = isLoading || isEmployeeLoading;
  return (
    <>
      <Grid container>
        <Grid
          item
          md={6}
          sx={{ alignItems: "center", display: "flex", padding: "0 0 30px" }}
        >
          <Box
            className="avatar"
            sx={{
              border: "2px solid rgb(208, 215, 222)",
              borderRadius: "15px",
              height: "100px",
              padding: "10px",
              width: "100px",
            }}
          >
            {loading ? (
              <Skeleton
                animation="wave"
                variant="rounded"
                height={"100%"}
              ></Skeleton>
            ) : (
              <img
                src={contractorOrganisation?.organisationLogo}
                alt={contractorOrganisation?.organisationName}
                loading="lazy"
                style={{
                  maxHeight: "auto",
                  objectFit: "contain",
                  padding: "0",
                }}
                width="100%"
                height="100%"
              />
            )}
          </Box>
          <Box sx={{ display: "flex", gap: "10px", paddingLeft: "20px" }}>
            {loading ? (
              <Skeleton animation="wave" variant="text">
                <Typography variant="h2" sx={{ fontWeight: "bold" }}>
                  {"Organisation name"}
                </Typography>
              </Skeleton>
            ) : (
              <Typography
                data-test-id="organisation-name"
                variant="h2"
                sx={{ fontWeight: "bold" }}
              >
                {contractorOrganisation?.organisationName}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          sx={{
            display: "flex",
            padding: "0 !important",
          }}
          justifyContent="flex-end"
        >
          <Box
            sx={{
              alignItems: "flex-end",
              display: "flex",
              height: "40px",
              justifyContent: "flex-end",
            }}
          >
            {isDownloading ? (
              <CircularProgressWithLabel value={downloadProgress} />
            ) : contractorPackage && contractorPackage?.uri ? (
              <StyledButton
                data-test-id="download-agreement-button"
                color="primary"
                size="large"
                loading={loading}
                className="blackBtn"
                sx={{ color: "white", fontSize: "12px" }}
                onClick={() => {
                  onDownload(
                    contractorPackage.uri || "",
                    getDocumentName(
                      "Contractor Agreement",
                      contractorPackage.uri || "",
                    ),
                  );
                }}
                startIcon={<DownloadIcon />}
              >
                Download Agreement
              </StyledButton>
            ) : null}
          </Box>
        </Grid>

        <Box sx={{ padding: "0 0 20px" }}>
          <Typography
            variant="subtitle2"
            sx={{
              color: "#000",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            Contact Details
          </Typography>
        </Box>
        <Grid container xs={12} md={12}>
          <Grid sx={{ padding: "0", width: "calc(30% - 20px)" }}>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Typography
                variant="subtitle1"
                sx={{
                  marginBottom: "5px",
                  minWidth: "72px",
                }}
              >
                Contact
              </Typography>
              {loading ? (
                <Skeleton animation="wave" variant="text">
                  <Typography variant="subtitle1">
                    {"Representative name"}
                  </Typography>
                </Skeleton>
              ) : (
                <Typography
                  data-test-id="contact-name"
                  variant="subtitle1"
                  sx={{
                    fontWeight: "bold",
                    width: "calc(100% - 72px)",
                    wordBreak: "break-word",
                  }}
                >
                  {contractorOrganisation?.representativeName}
                </Typography>
              )}
            </Box>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Typography
                variant="subtitle1"
                sx={{
                  marginBottom: "5px",
                  minWidth: "72px",
                }}
              >
                Phone
              </Typography>
              {loading ? (
                <Skeleton animation="wave" variant="text">
                  <Typography variant="subtitle1">{"Phone"}</Typography>
                </Skeleton>
              ) : (
                <Typography
                  data-test-id="phone-number"
                  variant="subtitle1"
                  sx={{
                    fontWeight: "bold",
                    width: "calc(100% - 72px)",
                    wordBreak: "break-word",
                  }}
                >
                  {contractorOrganisation?.contactInfo.phoneNumber}
                </Typography>
              )}
            </Box>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Typography
                variant="subtitle1"
                sx={{
                  marginBottom: "5px",
                  minWidth: "72px",
                }}
              >
                Email
              </Typography>
              {loading ? (
                <Skeleton animation="wave" variant="text">
                  <Typography variant="subtitle1">
                    {"Representative name"}
                  </Typography>
                </Skeleton>
              ) : (
                <Typography
                  data-test-id="contact-email"
                  variant="subtitle1"
                  sx={{
                    fontWeight: "bold",
                    width: "calc(100% - 72px)",

                    wordBreak: "break-word",
                  }}
                >
                  {contractorOrganisation?.representativeEmail}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid
            sx={{ margin: "0 10px 0", padding: "0", width: "calc(40% - 20px)" }}
          >
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Typography
                variant="subtitle1"
                sx={{
                  marginBottom: "5px",
                  minWidth: "100px",
                }}
              >
                Address Line 1
              </Typography>
              {loading ? (
                <Skeleton animation="wave" variant="text">
                  <Typography variant="subtitle1">
                    {"Address line 1"}
                  </Typography>
                </Skeleton>
              ) : (
                <Typography
                  data-test-id="address-line-1"
                  variant="subtitle1"
                  sx={{
                    fontWeight: "bold",
                    width: "calc(100% - 72px)",
                    wordBreak: "break-word",
                  }}
                >
                  {contractorOrganisation?.contactInfo.addressLine1}
                </Typography>
              )}
            </Box>
            <Box sx={{ display: "flex", gap: "10px", marginTop: "5px" }}>
              <Typography
                variant="subtitle1"
                sx={{
                  marginBottom: "5px",
                  minWidth: "100px",
                }}
              >
                Address Line 2
              </Typography>
              {loading ? (
                <Skeleton animation="wave" variant="text">
                  <Typography variant="subtitle1">
                    {"Address line 2"}
                  </Typography>
                </Skeleton>
              ) : (
                <Typography
                  data-test-id="address-line-2"
                  variant="subtitle1"
                  sx={{
                    fontWeight: "bold",
                    width: "calc(100% - 72px)",
                    wordBreak: "break-word",
                  }}
                >
                  {contractorOrganisation?.contactInfo.addressLine2}
                </Typography>
              )}
            </Box>
            <Box sx={{ display: "flex", gap: "10px", marginTop: "5px" }}>
              <Typography
                variant="subtitle1"
                sx={{
                  marginBottom: "5px",
                  minWidth: "100px",
                }}
              >
                Country
              </Typography>
              {loading ? (
                <Skeleton animation="wave" variant="text">
                  <Typography variant="subtitle1">{"Country"}</Typography>
                </Skeleton>
              ) : (
                <Typography
                  data-test-id="country"
                  variant="subtitle1"
                  sx={{
                    fontWeight: "bold",
                    width: "calc(100% - 72px)",
                    wordBreak: "break-word",
                  }}
                >
                  {contractorOrganisation?.contactInfo.country}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid
            sx={{ margin: "0 10px 0", padding: "0", width: "calc(30% - 20px)" }}
          >
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Typography
                variant="subtitle1"
                sx={{
                  marginBottom: "5px",
                  minWidth: "100px",
                }}
              >
                State
              </Typography>
              {loading ? (
                <Skeleton animation="wave" variant="text">
                  <Typography variant="subtitle1">{"State"}</Typography>
                </Skeleton>
              ) : (
                <Typography
                  data-test-id="state"
                  variant="subtitle1"
                  sx={{
                    fontWeight: "bold",
                    maxWidth: "500px",
                    width: "calc(100% - 100px)",
                  }}
                >
                  {contractorOrganisation?.contactInfo.state}
                </Typography>
              )}
            </Box>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Typography
                variant="subtitle1"
                sx={{
                  marginBottom: "5px",
                  minWidth: "100px",
                }}
              >
                City
              </Typography>
              {loading ? (
                <Skeleton animation="wave" variant="text">
                  <Typography variant="subtitle1">{"City"}</Typography>
                </Skeleton>
              ) : (
                <Typography
                  data-test-id="city"
                  variant="subtitle1"
                  sx={{
                    fontWeight: "bold",
                    maxWidth: "500px",
                    width: "calc(100% - 100px)",
                  }}
                >
                  {contractorOrganisation?.contactInfo.city}
                </Typography>
              )}
            </Box>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Typography
                variant="subtitle1"
                sx={{
                  marginBottom: "5px",
                  minWidth: "100px",
                }}
              >
                Postcode
              </Typography>
              {loading ? (
                <Skeleton animation="wave" variant="text">
                  <Typography variant="subtitle1">{"Postcode"}</Typography>
                </Skeleton>
              ) : (
                <Typography
                  data-test-id="post-code"
                  variant="subtitle1"
                  sx={{
                    fontWeight: "bold",
                    maxWidth: "500px",
                    width: "calc(100% - 100px)",
                  }}
                >
                  {contractorOrganisation?.contactInfo.postCode}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ padding: "20px 0" }}>
          <Typography
            variant="subtitle2"
            sx={{
              color: "#000",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            Employee Details
          </Typography>
        </Box>
        <Grid item xs={12} md={12} sx={{ padding: "0 !important" }}>
          <ViewEmployeeTable
            employeeCount={employeeCount}
            loading={loading}
            setPage={setPage}
            employees={employees}
            project={project}
          />
        </Grid>
      </Grid>
    </>
  );
};
