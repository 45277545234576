import {
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { GreenMetricCategorySubmissionLineItem } from "social-pro-common/interfaces/greenMetricCategorySubmission";
import { GreenMetricLineItem } from "social-pro-common/interfaces/greenMetric";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { SustainabilityTable } from "@stories/molecules/SustainabilityTable/SustainabilityTable";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { ChangeEvent, FocusEvent } from "react";

interface SustainabilityProps {
  loading: boolean;
  contractorPackage?: ContractorPackageLineItem;
  project?: ProjectLineItem;
  greenMetric?: GreenMetricLineItem;
  reportId: string;
  greenMetricCategorySubmissions: GreenMetricCategorySubmissionLineItem[];
  isSubmitting: boolean;
  commentValue: string;
  readOnly?: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
  createGreenMetricCategorySubmission: (
    greenMetricSubmission: GreenMetricCategorySubmissionLineItem,
  ) => Promise<void>;
  updateGreenMetricCategorySubmission: (
    greenMetricSubmission: GreenMetricCategorySubmissionLineItem,
  ) => Promise<void>;
  updateComment: (comment: string, save: boolean) => Promise<void>;
  handlOnBack?: () => void;
}

export const Sustainability = ({
  commentValue,
  contractorPackage,
  createGreenMetricCategorySubmission,
  greenMetric,
  greenMetricCategorySubmissions,
  handlOnBack,
  isSubmitting,
  loading,
  project,
  readOnly,
  reportId,
  setIsSubmitting,
  updateComment,
  updateGreenMetricCategorySubmission,
}: SustainabilityProps) => {
  return (
    <>
      <Grid
        container
        sx={{ margin: "0", padding: "30px 40px 0" }}
        className="borderTop"
      >
        <Grid item xs={12} md={6}>
          {loading ? (
            <Stack direction="row" spacing={2}>
              {handlOnBack ? (
                <Skeleton variant="circular">
                  <IconButton color="primary" size="large">
                    <ArrowBackIosNewOutlinedIcon />
                  </IconButton>
                </Skeleton>
              ) : null}
              <Stack spacing={2}>
                <Skeleton animation="wave">
                  <Typography>Target Name</Typography>
                  <Typography>
                    Please remember to attach relevant dockets, invoices, and/or
                    other records.
                  </Typography>
                </Skeleton>
              </Stack>
            </Stack>
          ) : (
            <Stack direction="row" spacing={2}>
              {handlOnBack ? (
                <Tooltip
                  TransitionComponent={Zoom}
                  placement="top"
                  title="Back to package details"
                >
                  <IconButton
                    data-test-id="back-button-top"
                    size="large"
                    onClick={handlOnBack}
                    sx={{
                      color: "black",
                      height: "auto",
                      padding: "5px",
                      width: "auto",
                    }}
                  >
                    <ArrowBackIosNewOutlinedIcon />
                  </IconButton>
                </Tooltip>
              ) : null}
              <Stack spacing={2}>
                <Typography variant="h2">
                  {greenMetric?.reportTargetName}
                </Typography>
                <Typography>
                  Please remember to attach relevant dockets, invoices, and/or
                  other records.
                </Typography>
              </Stack>
            </Stack>
          )}
        </Grid>
      </Grid>
      <Grid container sx={{ margin: "0", padding: "0px 40px 40px" }}>
        <Grid item xs={12} md={12} sx={{ paddingTop: "10px" }}>
          <SustainabilityTable
            loading={loading}
            project={project}
            reportId={reportId}
            greenMetric={greenMetric}
            contractorPackage={contractorPackage}
            greenMetricCategorySubmissions={greenMetricCategorySubmissions}
            createGreenMetricCategorySubmission={
              createGreenMetricCategorySubmission
            }
            updateGreenMetricCategorySubmission={
              updateGreenMetricCategorySubmission
            }
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <StyledTextField
            data-test-id="hours-comments-input"
            variant="outlined"
            loading={loading}
            disabled={loading || isSubmitting || readOnly}
            id={"categoryComments"}
            name={`categoryComments`}
            label={`If amounts are estimated, please describe the method used.`}
            fullWidth
            multiline
            maxLength={250}
            rows={5}
            margin="dense"
            onChange={async (e: ChangeEvent<HTMLInputElement>) => {
              await updateComment(e.target.value, false);
            }}
            onBlur={async (e: FocusEvent<HTMLInputElement>) => {
              setIsSubmitting(true);
              await updateComment(e.target.value, true);
              setIsSubmitting(false);
            }}
            value={commentValue}
          />
        </Grid>
      </Grid>
    </>
  );
};
