import { Buffer } from "buffer";
Buffer.from("anything", "base64");

import { AuthProvider, useAuthContext } from "@hooks/context/useAuthContext";
import ErrorBoundary from "./views/ErrorBoundary/ErrorBoundary";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Navigate, Route, Routes } from "react-router-dom";
import { RouteInfo, getRoutes } from "routes/routes";
import ScrollToTop from "@stories/atoms/ScrollToTop/ScrollToTop";
import { Amplify } from "aws-amplify";
import ThemeCustomization from "themes";
import { ToastContainer } from "react-toastify";
import Locales from "@stories/ui-component/Locales";
import "react-toastify/dist/ReactToastify.css";
import "./global.css";
import "react-awesome-button/dist/styles.css";
import "react-datasheet-grid/dist/style.css";
import "./App.css";
import { getAmplifyConfiguration, isProdEnv, IS_PROD_ENV } from "init";
import * as Sentry from "@sentry/react";
import Intercom, { update }  from '@intercom/messenger-js-sdk';

Sentry.init({
  dsn: "https://abc088ed2240bd13b6607d58cbd2a7c1@o4507807835226112.ingest.us.sentry.io/4507807838830592",
  environment: isProdEnv() ? "production" : "development",
  integrations: [Sentry.browserTracingIntegration()],
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "socialproapp.com",
    /^https:\/\/portal\.socialproapp\.com/,
    /^https:\/\/bmd\.socialproapp\.com/,
    /^https:\/\/dev\.socialproapp\.com/,
    "localhost",
  ], // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
});

Amplify.configure(getAmplifyConfiguration());

const RoutedPath = (index: number, routeInfo: RouteInfo) => {
  const Layout = routeInfo.layout;
  const Component = routeInfo.component;
  return (
    <Route
      key={`route-${index}`}
      index={routeInfo.index}
      path={routeInfo.path}
      element={<Layout children={<Component />} />}
    />
  );
};

const AuthenticatedRouter = () => {
  const { loggedIn, userProfile } = useAuthContext();
  const routes = getRoutes(loggedIn, userProfile);
  const defaultPath = routes.find((r) => r.index);

  if (!defaultPath) {
    throw Error("No default path defined for this set of routes");
  }

  if (loggedIn) {
    update({
      email: userProfile?.email,
      id: userProfile?.id,
      name: userProfile?.name,
      organisationId: userProfile?.organisationId,
    });
  }

  return (
    <Routes>
      {routes.map((r, index) => RoutedPath(index, r))}
      <Route path="*" element={<Navigate to={defaultPath.path} replace />} />
    </Routes>
  );
};

function App() {
  if (IS_PROD_ENV) {    
    Intercom({
      app_id: "ir9errip",
    });
  } else {
    Intercom({
      app_id: "lpvhvbpg",
    });
  }
  return (
    <ErrorBoundary>
      <Sentry.ErrorBoundary
        fallback={
          <p>Something went wrong - the SocialPro team have been notified</p>
        }
      >
        <ThemeCustomization>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Locales>
              <ScrollToTop />
              <AuthProvider>
                <AuthenticatedRouter />
              </AuthProvider>
              <ToastContainer position="bottom-right" />
            </Locales>
          </LocalizationProvider>
        </ThemeCustomization>
      </Sentry.ErrorBoundary>
    </ErrorBoundary>
  );
}

export default App;
