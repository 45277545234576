import { useProjectContext } from "@hooks/context/useProjectContext";
import { Grid } from "@mui/material";
import { useProfileContext } from "@hooks/context/useProfileContext";
import { ReportColumn } from "@stories/organisms/ReportColumn/ReportColumn";
import { PdfDownlodDialog } from "@stories/molecules/PdfDownlodDialog/PdfDownlodDialog";
import usePdf from "@hooks/crud/downloadFiles/usePdf";
import * as RouteHelper from "@utils/routes";
import { useNavigate } from "react-router-dom";
import { ReportStatus } from "social-pro-common/entities/projectReportSubmission";
import { PageHeader } from "@stories/molecules/PageHeader/PageHeader";
import { useReportSocialSubmission } from "@hooks/crud/reportSocialSubmission/useReportSocialSubmission";

export const PackageReports = () => {
  const navigate = useNavigate();

  const { isAuthProfileLoading, userOrganisation } = useProfileContext();

  const {
    contractorPackage,
    isPrimaryPackage,
    isProjectLoading,
    selectedProject,
  } = useProjectContext();

  const { downloadProgress, downloadStep, getPdfInfo } = usePdf();

  const { isReportLoading, reportSubmissions } = useReportSocialSubmission(
    contractorPackage?.id,
  );

  const loading = isAuthProfileLoading || isProjectLoading || isReportLoading;

  const orderedSubmissions = reportSubmissions.slice().reverse();

  const upcomingReports = orderedSubmissions.filter(
    (r) => r.reportStatus === ReportStatus.Upcoming,
  );

  const currentReports = orderedSubmissions.filter(
    (r) =>
      r.reportStatus === ReportStatus.Overdue ||
      r.reportStatus === ReportStatus.ToSubmit ||
      r.reportStatus === ReportStatus.InProgress,
  );

  const submittedReports = orderedSubmissions.filter(
    (r) =>
      r.reportStatus === ReportStatus.Complete ||
      r.reportStatus === ReportStatus.Accepted,
  );

  const handleDownloadReport = (reportId: string, reportTitle: string) => {
    if (selectedProject && contractorPackage) {
      getPdfInfo(
        selectedProject.id,
        reportId,
        reportTitle,
        contractorPackage.id,
      );
    }
  };

  const handlViewReport = (reportMonthId: string) => {
    if (selectedProject && contractorPackage) {
      navigate(
        RouteHelper.wizard(
          selectedProject.id,
          contractorPackage.id,
          reportMonthId,
        ),
      );
    }
  };

  return (
    <>
      <Grid
        container
        sx={{
          margin: "0",
          marginBottom: "0",
          width: "100%",
        }}
      >
        <PageHeader
          loading={loading}
          title="Package Social Reports"
          subTitle={userOrganisation?.organisationName}
        />

        <Grid
          sx={{
            display: "flex",
            minHeight: "90vh",
            padding: "20px 30px 30px !important",
            width: "100%",
          }}
        >
          <ReportColumn
            dataTestId="upcoming-reports"
            loading={loading}
            title={"Upcoming"}
            subTitle={"Reports due in future months"}
            dueDate={selectedProject?.reportingDueDate || 0}
            reports={upcomingReports}
            isPrimaryPackage={false}
            handlViewReport={handlViewReport}
            handleDownloadReport={handleDownloadReport}
          />
          <ReportColumn
            dataTestId="current-reports"
            loading={loading}
            title={"Current"}
            subTitle={"Reports that require urgent action"}
            dueDate={selectedProject?.reportingDueDate || 0}
            reports={currentReports}
            isPrimaryPackage={false}
            handlViewReport={handlViewReport}
            handleDownloadReport={handleDownloadReport}
          />
          <ReportColumn
            dataTestId="completed-reports"
            loading={loading}
            title={"Complete"}
            subTitle={"Reports previously submitted"}
            dueDate={selectedProject?.reportingDueDate || 0}
            reports={submittedReports}
            isPrimaryPackage={isPrimaryPackage}
            handlViewReport={handlViewReport}
            handleDownloadReport={handleDownloadReport}
          />
        </Grid>
        <PdfDownlodDialog
          downloadStep={downloadStep}
          total={downloadProgress}
          title={"Package Report Export"}
        />
      </Grid>
    </>
  );
};
