import { useCallback, useState, useEffect } from "react";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { decodeContractorPackage } from "social-pro-common/decoders/contractorPackage";
import { getApiData } from "@hooks/utils/api";
import { getImageUrl } from "@hooks/utils/useUpload";
import { ContractorPackage } from "social-pro-common/entities/contractorPackage";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";
import { useOrganisation } from "../organisation/useOrganisation";
import { catchSentryError } from "@utils/sentry";

export const useContractorPackageDetails = (contractorPackageId?: string) => {
  const { getOrganisation } = useOrganisation();

  const [contractorPackageDetails, setContractorPackageDetails] =
    useState<ContractorPackageLineItem>();
  const [contractorOrganisation, setContractorOrganisation] =
    useState<OrganisationLineItem>();
  const [isContractorPackageLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const getContractorPackageDetails = useCallback(
    async (id: string) => {
      try {
        setIsLoading(true);
        const contractorPackageResult = await getApiData(
          "getContractorPackage",
          "contractorPackage",
          id,
        );
        if (
          !contractorPackageResult ||
          Object.keys(contractorPackageResult.data).length === 0
        ) {
          return undefined;
        }
        const contractorPackage = await decodeContractorPackage(
          contractorPackageResult.data as ContractorPackage,
          getImageUrl,
        );
        const organisation = await getOrganisation(
          contractorPackage.contractorId,
        );
        setContractorPackageDetails(contractorPackage);
        setContractorOrganisation(organisation || undefined);
      } catch (error) {
        catchSentryError(error);
        setError("Could not fetch contractorPackage");
      } finally {
        setIsLoading(false);
      }
    },
    [getOrganisation, getImageUrl, decodeContractorPackage],
  );

  useEffect(() => {
    if (contractorPackageId) {
      getContractorPackageDetails(contractorPackageId);
    }
  }, [contractorPackageId, getContractorPackageDetails]);

  return {
    contractorOrganisation,
    contractorPackageDetails,
    error,
    getContractorPackageDetails,
    isContractorPackageLoading,
  };
};
